import React from "react";
import GetVolumeWeight from "./GetVolumeWeight";
import ChargeableWeight from "./ChargeableWeight";

function ConsolidationActualWeight(row) {
    let actualWeight = row.weight_value;
    if(row.parcel_children.length > 0) {
        row.parcel_children.forEach(parcel => {
            if (parcel.uuid.match(/BC.*/) || parcel.uuid.match(/IC.*/)) {
                let actualWeight1 = parcel.weight_value;
                actualWeight += actualWeight1
            }
        });
    }
    return actualWeight;
};
export default ConsolidationActualWeight;
