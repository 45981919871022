import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import Header from "./Layout/Header";
import PromoCodeTable from "components/PromoCodes/PromoCodeTable";
import ScaleLoader from "react-spinners/ScaleLoader";
import * as config from "config/config";
import { API_CONSTANTS } from "constants/constants";
import DateRangePickerCustom from "components/Common/DateRangePicker/DateRangePickerCustom";

const PromoCodes = () => {
    const [promoCodes, setPromoCodes] = useState([]);
    const [loading, setLoading] = useState(true);

    //Date Picker Logic
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    var token = localStorage.getItem("accessToken");
    useEffect(() => {
        if(!token){
            window.location.reload(false);
        }
    }, [token]);

    useEffect(() => {
        setLoading(true);
        const dateObj2 = {
            start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : "2020-04-15",
            end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : moment().add(1,'days').format("YYYY-MM-DD"),
        };
        const tokenConfig = {
            headers: { Authorization: `Bearer ${token}` },
        };
        async function fetchData() {
            const promoCodesResponse = await axios.post(
                config.apiUrl + API_CONSTANTS.ALL_PROMO_CODES,
                dateObj2,
                tokenConfig
            );
            setLoading(false);
            setPromoCodes(promoCodesResponse.data.payload.allPromoCodes);
        }

        fetchData();
    }, [endDate]);

    return (
        <>
            {loading || !token ? (
                <div className="loader row justify-content-center">
                    <ScaleLoader color="#15397F" size={150} className=" mx-auto" />
                </div>
            ) : (
                <div className="promo-container">
                    <Header title="Promo Codes" records={promoCodes.length} data={promoCodes} name="Promo Codes"  buttonId="#promoExportButton">
                        <div className="mx-2">
                            <DateRangePickerCustom setStartDate={setStartDate} setEndDate={setEndDate}
                                startDate={startDate ? moment(startDate).format("YYYY-MM-DD") : "2020-04-15"}
                                endDate={endDate ? moment(endDate).format("YYYY-MM-DD") : moment().add(1,'days').format("YYYY-MM-DD")} />
                        </div>
                    </Header>

                    <div className="row  d-flex px-2">
                        <PromoCodeTable promoCodesData={promoCodes} />
                    </div>
                </div>
            )}
        </>
    );
};

export default PromoCodes;
