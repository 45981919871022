import React, { memo } from "react";
import { Bar } from "react-chartjs-2";

const MemberShipChart = ({ type, data, backgroundColor, label, sidelabels }) => {
    let maxdataLength = Math.max.apply(Math, data.map(function(o) { return o.count; })) + 50;

    const options = {
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                anchor: "end",
                color: "#374151",
                align: "right",
                offset: 8,
                font: {
                    weight: "bold",
                },
                formatter: Math.round,
            },
        },
        indexAxis: type,
        maintainAspectRatio: false,
        scales: {
            y: {
                grid: {
                    display: false,
                },
                ticks: {
                    align: "center",
                    crossAlign: "far",
                    color: "#2D3748",
                    font: {
                        size: 14,
                        weight: "500",
                        
                    }
                },
            },
            x: {
                max: maxdataLength,
                grid: {
                    borderDash: [2, 5],
                },
                ticks: {
                    color: "#9CA3AF",
                    font: {
                        size: 12,
                    },
                },
            },
        },
    };

    const dataHorBar = {
        labels: sidelabels,
        datasets: [
            {
                barThickness: 30,
                label: label,
                backgroundColor: backgroundColor,
                borderWidth: 1,
                ...(!!data.length && {
                    data: data?.map((val) => {
                        return val.count;
                    }),
                }),
            },
        ],
    };
    return (
        <React.Fragment>
            <Bar options={options} data={dataHorBar} width={100} height={50} />
        </React.Fragment>
    );
};

export default memo(MemberShipChart);
