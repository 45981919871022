import React from "react";
import { Route, Switch} from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Login from "../Login";

function WithoutLogin() {

    return ( < >
        <Row className="mx-0">
            <Col xs={12} sm={12} md={6} lg={3} className="pb-4 mx-auto login-container">  
                <Switch>
                    <Route
                        path="/login"
                        render={() => <Login />}
                    />
                    <Route
                        path="/"
                        render={() => <Login />}
                    />
                </Switch>
            </Col>
        </Row>      
    </>
    );
}

export default WithoutLogin;