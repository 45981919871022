import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import { useTranslation } from "react-i18next";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Col, Row  } from "react-bootstrap";
import filterFactory, { textFilter} from 'react-bootstrap-table2-filter';
import { SearchBar, SelectFilterColums } from "components/Tables/Columns";
import { ChevronUp, ChevronDown } from 'react-bootstrap-icons';
import pageListRenderer from '../Tables/PageListRenderer';
import sizePerPageOptionRenderer from '../Tables/SizePerPageOptionRenderer';
import { logoUrl } from "config/config";
import CustomTotal from "../Tables/CustomTotal";
import ChargeableWeight from "../Tables/ChargeableWeight"
import GetVolumeWeight from "../Tables/GetVolumeWeight";
import moment from "moment";
import { GLOBAL_CONSTANTS } from "constants/constants";

let suiteFilter, userNameFilter;

function PickSuite(props) {
  const { t } = useTranslation();
  props.setButtonId("#pickExportButton");

  const onFilter = (value, id) => {
    if(!value)
      value = '';
    if(id === 'user')
      userNameFilter(value);
    if(id === 'suite'){
      suiteFilter(value);
    }
  };

 const Columns = [
  {
    dataField: "created_at",
    text: t('Date'),
    sort:true,
    style: {
      "width": 245,
    },
    formatter: (cell, row) => {
      if(cell)
        return moment(cell).utc().format("DD MMM yyyy");
    },
  },
  {
    dataField: "suite",
    text: t('Suite_Id'),
    style: {
      "width": 245,
      "color": "#1E88E5"
    },
    filter: textFilter({
      getFilter: (filter) => {
        suiteFilter = filter;
      }
    })
  },
  {
    text: t('Parcels_to_Pick'),
    dataField: "ParcelSuites.length",
    sort: true,
  },
];

const expandRow = {
  renderer: row => {
    if(row.ParcelSuites.length <= 0)
    return (
      <Row className="expanding-row px-4 py-4">
          <Col xs={12} sm={12} md={12} lg={5}>
            {t('No_Record')}
          </Col>
      </Row>);

  const columns = [
      {
        dataField: "created_at",
        text: t('Date'),
        style: {
          width: 100,
        },
        sort:true,
        formatter: (cell, row) => {
          if(cell)
            return moment(cell).utc().format("DD MMM yyyy");
        },
      },
      {
          dataField: "parcel.uuid",
          text: t("Parcel ID"),
          classes: "highlighted-text",
      },
      {
        text: t("AWB"),
        dataField: "parcel.awb",
      },
      {
        text: t('Customer'),
        dataField: "user.name",
        formatter: (cell, row) => {
          return <div>
            <span className="d-flex">{row.user.user_pin}</span>
            <span className="font-12">{cell}</span></div>;
        },
        csvFormatter: (cell, row) => {
          return row.user.user_pin +" ,"+ cell;
        },
      },
      {
        text: t("Destination"),
        dataField: "parcel.ParcelAddress.country",
      },
      {
          text: t("Items"),
          dataField: "parcel.ParcelHasItems",
          formatter: (cell, row) => {
            let quantity = 0
            cell.forEach(parcelItem => {
              quantity += parcelItem.quantity
            });
            return quantity
          },
          csvFormatter: (cell, row) => {
            let quantity = 0
            cell.forEach(parcelItem => {
              quantity += parcelItem.quantity
            });
            return quantity
          },
      },
      {
          dataField: 'parcel.weight_value',
          text: t('A_Wt'),
          style: {
            width: 70,
          },
          formatter: (cell, row) => {
              return (
                  cell ?
                  <div>
                      <strong>{cell}</strong> {row.parcel?.weight_unit}
                  </div>
              :
              <div>
                  <strong>{0}</strong>
              </div>);
          },
      },
      {
          dataField: "parcel.weight_value",
          text: t("Ch_Wt"),
          style: {
            width: 70,
          },
          formatter: (cell, row) => {
              const actualWeight = row.parcel?.weight_value;
              const VolumeWeight = GetVolumeWeight(row.parcel);
              const chargeableWeight = ChargeableWeight(VolumeWeight, actualWeight);
              return (
              chargeableWeight ?
                  <div>
                      <strong>{chargeableWeight?.toFixed(2)}</strong> {row.parcel?.weight_unit}
                  </div>
              :
              <div>
                  <strong>{0}</strong>
              </div>);
          },
          csvFormatter: (cell, row) => {
            const actualWeight = row.parcel?.weight_value;
            const VolumeWeight = GetVolumeWeight(row.parcel);
            const chargeableWeight = ChargeableWeight(VolumeWeight, actualWeight);
            return chargeableWeight?.toFixed(2);
        },
      },
      {
          dataField: 'parcel.dimension_length',
          text: t('Dims'),
          formatter: (cell, row) => {
              const w = row.parcel?.dimension_width;
              const h = row.parcel?.dimension_height;
              
              return cell?cell+" x "+w+" x "+h+"  "+row.parcel?.dimensions_unit:'-';
          },
      },
      {
        dataField: "parcel.amount",
        text: t("Fees"),
        formatter: (cell, row) => {
            return (
                <div>
                    <strong>{cell}</strong> {row.parcel?.currency}
                </div>
            );
        },
        csvFormatter: (cell, row) => {
            return cell +" "+row.parcel?.currency;
        },
      },
      {
        text: t('Status'),
        dataField: "parcel.ParcelHasParcelStatus.ParcelStatus.name",
        formatter: (cell, row) => {
          return (cell)
            ?<div className={cell.toLowerCase().replace(/[^a-zA-Z ]/g, " ").split(' ').join('-')}>{cell}</div>
            :""
        },
      },
      {
        text: t('Shipping'),
        dataField: "parcel.ShippingCompany.shipping_name",
        style: {
          "width": 100
        },
        formatter: (cell, row) => {
          if(cell)
            return (
              <img width="100" height="80" src={logoUrl + row.parcel?.ShippingCompany.logo} alt="shipping_company" />
          );
        }
      }]
  return (
      <BootstrapTable
          keyField="id"
          data={row.ParcelSuites}
          columns={columns}
          wrapperClasses="inner-table my-3 mx-4 "
          headerClasses="header-class"
          bordered={false}
          noDataIndication={t("No_Record")}
          rowClasses="inner-table-row"
          condensed={true} />
  )},
  showExpandColumn: true,
  expandByColumnOnly: true,
  expandHeaderColumnRenderer: ({ isAnyExpands }) => {
    return '';
  },
  expandColumnRenderer: ({ expanded }) => {
    if (expanded) {
      return (
        <ChevronUp color="#2D3748" size={10} className="mx-2 my-3 arrow-icon" />
      );
    }
    return (
      <ChevronDown color="#2D3748" size={10} className="mx-2 my-3 arrow-icon" />
    );
  }
};


  const options = {
    sizePerPage: GLOBAL_CONSTANTS.SIZE_PER_PAGE,
    hideSizePerPage: false,
    hidePageListOnlyOnePage: true,
    sizePerPageOptionRenderer,
    showTotal: true,
    pageListRenderer,
    paginationTotalRenderer: CustomTotal,
  };
  
  const { ExportCSVButton } = CSVExport;

  return (
    <div className="table-responsive">
      {Columns &&
        <ToolkitProvider
          keyField="id"
          data={ props.pickedData }
          columns={ Columns }
          search={ { searchFormatted: true } }
          exportCSV={{
                        fileName: "PickTable.csv",
                        noAutoBOM: false,
                        exportAll: false,
                        onlyExportFiltered: true,
                    }}
        >
          {
            props => (
              <div className="table-container my-4">
                <Row className="search mx-2 justify-content-end">
                  <Col xs={5} sm={4} md={3} lg={2}>
                    <SelectFilterColums preFilteredRows={props.baseProps.data} id="suite" text={t("Suite")} { ...props.searchProps } onFilterClick={onFilter}/>
                  </Col>
                  <Col xs={5} sm={4} md={3} lg={3} className="search-width">
                    <SearchBar { ...props.searchProps } />
                  </Col>
                  <Col className="d-none">
                        <ExportCSVButton {...props.csvProps} id="pickExportButton">
                            Export
                        </ExportCSVButton>
                  </Col>
                </Row>
                <Row className="table-data my-3">
                  <Col>
                    <BootstrapTable
                      expandRow={ expandRow }
                      { ...props.baseProps }
                      wrapperClasses="table-wrapper my-3 "
                      headerClasses="header-class"
                      bordered={ false }
                      noDataIndication={t("No_Record")}
                      filter={ filterFactory() }
                      rowClasses="custom-table-row"
                      condensed={true}
                      pagination={ paginationFactory(options) }
                    />
                  </Col>
                </Row>
              </div>
            )
          }
        </ToolkitProvider>
      }
    </div>
  );
}

export default PickSuite;
