import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useTranslation } from "react-i18next";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Col, Row } from "react-bootstrap";
import filterFactory from "react-bootstrap-table2-filter";
import { SearchBar, SelectFilterColums } from "components/Tables/Columns";
import { textFilter } from "react-bootstrap-table2-filter";
import moment from "moment";
import { ChevronUp, ChevronDown } from "react-bootstrap-icons";
import pageListRenderer from "../Tables/PageListRenderer";
import sizePerPageOptionRenderer from "../Tables/SizePerPageOptionRenderer";
import CustomTotal from "../Tables/CustomTotal";
import ChargeableWeight from "../Tables/ChargeableWeight";
import GetVolumeWeight from "../Tables/GetVolumeWeight";
import { useParams } from "react-router-dom";
import { logoUrl } from "config/config";
import { GLOBAL_CONSTANTS } from "constants/constants";

let countryTypeFilter, customerFilter, suitsFilter, statusFilter;

function InProcessParcelsTable(props) {
    const { t } = useTranslation();
    let countryTypeFilter, customerFilter, suitsFilter, statusFilter;
    let { type } = useParams();
    const onFilter = (value, id, subId) => {
        if (!value) value = "";
        if (id === "user") {
            if (subId === "country") countryTypeFilter(value);
            else customerFilter(value);
        } else if (id === "ParcelSuite") suitsFilter(value);
        else if (id === "ParcelHasParcelStatus") statusFilter(value);
    };

    function parcelCreatedDate(cell) {
        if (cell) return moment(cell).utc().format("DD MMM yyyy");
    }

    const parcelsColumns = [
        {
            dataField: "parcel_start_status.created_at",
            text: t('Date'),
            sort:true,
            formatter: (cell, row) => {
                return parcelCreatedDate(cell);
             },
             csvFormatter: (cell) => {
                 return parcelCreatedDate(cell);
             },
        },
        {
            dataField: "uuid",
            text: t("Parcel ID"),
            classes: "highlighted-text",
        },
        {
            text: t("Items"),
            dataField: "ParcelHasItems",
            formatter: (cell) => {
                let quantity = 0
                cell.forEach(parcelItem => {
                    quantity += parcelItem.quantity
                });
                return quantity
            },
            csvFormatter: (cell) => {
                let quantity = 0
                cell.forEach(parcelItem => {
                    quantity += parcelItem.quantity
                });
                return quantity
            },
        },
        {
            text: t("Destination"),
            filter: textFilter({
                getFilter: (filter) => {
                    countryTypeFilter = filter;
                },
            }),
            dataField: "ParcelAddress.country",
        },
        {
            text: t("Customer"),
            dataField: "user",
            formatter: (cell, row) => {
                return cell?<div>
                  <span className="d-flex">{row.user?.user_pin}</span>
                  <span className="font-12">{row.user?.name}</span></div>: '';
            },
            csvFormatter: (cell, row) => {
                return cell?row.user?.user_pin +" ,"+ row.user?.name:'';
            },
        },
          {
            text: t('Customer_Pin'),
            dataField: "user.user_pin",
            hidden:true,
            csvExport:false
          },
          {
            text: t('Customer_Name'),
            dataField: "user.name",
            hidden:true,
            csvExport:false
          },
        {
            text: t("Suite"),
            dataField: "ParcelSuite.suite.suite",
            classes: "highlighted-text",
            formatter: (cell) => {
                return cell?cell:'-';
            },
            csvFormatter: (cell, row) => {
                return cell?cell:'-';
            },
            
        },
        {
            dataField: 'weight_value',
            text: t('A_Wt'),
            formatter: (cell, row) => {
                return cell?<div>
                        <strong>{cell}</strong> {row.weight_unit}
                    </div>: <div>
                    <strong>{0}</strong>
                </div>
            },
        },
        {
            dataField: 'dimension_height',
            text: t('V_Wt'),
            formatter: (cell, row) => {
                let volumeWeight = GetVolumeWeight(row);
                
                return volumeWeight?<div>
                        <strong>{volumeWeight?.toFixed(2)}</strong> {row.weight_unit}
                    </div>: <div>
                    <strong>{0}</strong>
                </div>
            },
            csvFormatter: (cell, row) => {
                let volumeWeight = GetVolumeWeight(row);
                
                return volumeWeight?volumeWeight?.toFixed(2):'';
            },
        },
        {
            dataField: "weight_value",
            text: t("Ch_Wt"),
            sort: true,
            formatter: (cell, row) => {
                const actualWeight = row.weight_value;
                const VolumeWeight = GetVolumeWeight(row);
                const chargeableWeight = ChargeableWeight(VolumeWeight, actualWeight);
                return (
                chargeableWeight ?
                    <div>
                        <strong>{chargeableWeight?.toFixed(2)}</strong> {row.weight_unit}
                    </div>
                :
                <div>
                    <strong>{0}</strong>
                </div>);
            },
            csvFormatter: (cell, row) => {
                const actualWeight = row.weight_value;
                const VolumeWeight = GetVolumeWeight(row);
                const chargeableWeight = ChargeableWeight(VolumeWeight, actualWeight);
                return chargeableWeight?.toFixed(2);
            },
        },
        {
            dataField: "amount",
            text: t("Value"),
            sort: true,
            formatter: (cell, row) => {
                return cell?(
                    <div>
                        <strong>{cell}</strong> {row.currency}
                    </div>
                ): '';
            },
            csvFormatter: (cell, row) => {
                return cell?cell +" "+row.currency: '';
            },
        },
        {
            text: t("Status"),
            filter: textFilter({
                getFilter: (filter) => {
                    statusFilter = filter;
                },
                defaultValue: '',
            }),
            sort: true,
            dataField: "ParcelHasParcelStatus.ParcelStatus.name",
            formatter: (cell, row) => {
                return (cell)
                ?<div className={cell.toLowerCase().replace(/[^a-zA-Z ]/g, " ").split(' ').join('-')}>{cell}</div>
                :""
                
            },
        },
        // export only columns
        {
            dataField: 'awb',
            text: t('AWB'),
            hidden: true
        },
        {
            dataField: 'ParcelShipmentTracking.tracking_number',
            text: t('Tracking'),
            hidden: true,
            csvFormatter: (cell, row) => {
                return cell?cell:'-';
            },
        },
        {
            dataField: 'Eteller.name',
            text: t('Supplier'),
            hidden: true,
            csvFormatter: (cell, row) => {
                return cell?cell:'-';
            },
        },
        
        {
            dataField: 'dimension_length',
            text: t('Dimensions'),
            hidden: true,
            csvFormatter: (cell, row) => {
                const w = row.dimension_width;
                const h = row.dimension_height;
                return cell?cell+" x "+w+" x "+h+"  "+row.dimensions_unit:'-';
            },
        },
        
        {
            dataField: 'created_at',
            text: t("Created Day"),
            hidden: true,
            csvFormatter: (cell, row) => {
                return cell?moment(cell).utc().format("DD MMM yyyy hh:mm A"):""
            },
        },
        {
            dataField: 'updated_at',
            text: t("Last Update"),
            hidden: true,
            csvFormatter: (cell, row) => {
                return cell?moment(cell).utc().format("DD MMM yyyy hh:mm A"):""
            },
        },
        {
            dataField: "ShippingCompany.logo",
            text: t("Shipping"),
            style: {
                "width": 100
            },
            formatter: (cell, row) => {
                if(cell && (row?.ParcelPayment?.service_identifier === "")){
                    return (
                      <img width="100" height="80" src={logoUrl + cell} alt="shipping_company" />
                    ); 
                  } else if (cell && (row?.ParcelPayment?.service_identifier !== "")){
                    return (
                      <div>
                        <img width="100" height="80" src={logoUrl + cell} alt="shipping_company" />
                        <span style={{"font-size":"7px"}}><strong>{t(row?.ParcelPayment?.service_identifier?row?.ParcelPayment?.service_identifier.replace(/_/g, " "):'')}</strong></span>
                      </div>
                    ); 
                  }
                if (cell) return <img width="120" height="80" src={logoUrl + cell} alt="shipping_company" />;
            },
            csvFormatter: (cell, row) => {
                return row?.ShippingCompany?.shipping_name && row?.ParcelPayment?.service_identifier?t(row?.ShippingCompany?.shipping_name)+" - "+t(row?.ParcelPayment?.service_identifier.replace(/_/g, " ")): row?.ShippingCompany?.shipping_name;
            }
        },
        
    ];

    const options = {
        sizePerPage: GLOBAL_CONSTANTS.SIZE_PER_PAGE,
        hideSizePerPage: false,
        hidePageListOnlyOnePage: true,
        sizePerPageOptionRenderer,
        showTotal: true,
        pageListRenderer,
        paginationTotalRenderer: CustomTotal,

    };

    const { ExportCSVButton } = CSVExport;
    return (
        <div className="table-responsive px-4">
            {parcelsColumns && (
                <ToolkitProvider
                    keyField="uuid"
                    data={props.parcelsData}
                    columns={parcelsColumns}
                    search={{ searchFormatted: true }}
                    exportCSV={{
                        fileName: "InProcessParcelTable.csv",
                        noAutoBOM: false,
                        exportAll: false,
                        onlyExportFiltered: true,
                    }}
                >
                    {(props) => {
                        return (
                        <div className="table-container my-4">
                            <Row className="search dispatch-search mx-2">
                                <Col xs={12} sm={12} md={12} lg={9}>
                                    <Row>
                                        <Col xs={12} sm={6} md={6} lg={3} className="px-3">
                                            <SelectFilterColums
                                                preFilteredRows={props.baseProps.data}
                                                id="user"
                                                subId="country"
                                                text={t("Destination")}
                                                {...props.searchProps}
                                                onFilterClick={onFilter}
                                            />
                                        </Col>
                                        <Col xs={12} sm={6} md={6} lg={3}>
                                            <SelectFilterColums
                                                preFilteredRows={props.baseProps.data}
                                                id="ParcelHasParcelStatus"
                                                subId="ParcelStatus"
                                                otherSubId="name"
                                                text={t("Status")}
                                                {...props.searchProps}
                                                onFilterClick={onFilter}
                                                selectedVal={type}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={3} className="px-3">
                                    <SearchBar {...props.searchProps} />
                                </Col>
                                <Col className="d-none">
                                    <ExportCSVButton {...props.csvProps} id="parcelExportButton">
                                        Export
                                    </ExportCSVButton>
                                </Col>
                            </Row>
                            <Row className="table-data my-3">
                                <Col>
                                    <BootstrapTable
                                        { ...props.baseProps }
                                        wrapperClasses="table-wrapper my-3 "
                                        headerClasses="header-class"
                                        bordered={ false }
                                        noDataIndication={t("No_Record")}
                                        filter={ filterFactory() }
                                        rowClasses="custom-table-row"
                                        condensed={true}
                                        pagination={ paginationFactory(options) }
                                    />
                                </Col>
                            </Row>
                        </div>
                    )}}
                </ToolkitProvider>
            )}
        </div>
    );
}

export default InProcessParcelsTable;
