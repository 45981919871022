import React from "react";
import { FiPrinter } from "react-icons/fi";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import "./exportStyles.css";

export const ExportFromTable = ({ buttonId, csvData, fileName }) => {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ExportToExcel = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    const handleClick = () => {
        const actionbtn = document.querySelector(buttonId);
        if (!!actionbtn) actionbtn.click();
        else ExportToExcel(csvData, fileName);
    };

    return (
        <button onClick={handleClick} className="btn-export mx-2">
            <FiPrinter color="#2D3748"/>
            <p className="export-text">Export</p>
        </button>
    );
};
