import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import { useTranslation } from "react-i18next";
import ToolkitProvider, { CSVExport, Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Col, Row } from "react-bootstrap";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { SearchBar, SelectFilterColums } from "components/Tables/Columns";
import moment from "moment";
import { ChevronUp, ChevronDown } from 'react-bootstrap-icons';
import pageListRenderer from 'components/Tables/PageListRenderer';
import sizePerPageOptionRenderer from 'components/Tables/SizePerPageOptionRenderer';
import { logoUrl } from "config/config";
import ChargeableWeight from "components/Tables/ChargeableWeight";
import ConsolidationChargeableWeight from "components/Tables/ConsolidationChargeableWeight";
import ConsolidationActualWeight from "components/Tables/ConsolidationActualWeight";
import GetVolumeWeight from "components/Tables/GetVolumeWeight";
import ParcelItems from "components/Tables/ParcelItems";
import ParcelBoxes from "components/Tables/ParcelBoxes";
import CustomTotal from "components/Tables/CustomTotal";
import ShipmentValue from "components/Tables/ShipmentValue";
import { GLOBAL_CONSTANTS } from "constants/constants";

let parcelFilter, shippingFilter, userNameFilter, statusFilter;

function DispatchedTable(props) {
  const { t } = useTranslation();
  // const { SearchBar } = Search;  
  const onFilter = (value, id) => {
    if (!value)
      value = '';

    if (id === 'user')
      userNameFilter(value);
    else if (id === 'ShippingCompany')
      shippingFilter(value);
    else if (id === 'ParcelHasParcelStatus')
      statusFilter(value);
    else if (id === 'suite')
      parcelFilter(value);
  };



  const Columns = [
    {
      dataField: "parcel_start_status.created_at",
      text: t('D. Date'),
      sort: true,
      style: {
        "width": 100
      },
      formatter: (cell, row) => {
        if (cell)
          return moment(cell).utc().format("DD MMM yyyy");
      },
      csvFormatter: (cell, row) => {
        return moment(cell).utc().format("DD MMM yyyy");
      },
    },
    {
      dataField: "ParcelHasParcelStatus.created_at",
      text: t('S. Date'),
      sort: true,
      style: {
        "width": 100
      },
      formatter: (cell, row) => {
        if (cell)
          return moment(cell).utc().format("DD MMM yyyy");
      },
      csvFormatter: (cell, row) => {
        return moment(cell).utc().format("DD MMM yyyy");
      },
    },
    {
      text: t("Tracking"),
      dataField: "ParcelShipmentTracking.tracking_label",
      classes: "highlighted-text",
      csvExport: false,
      formatter: (cell, row) => {
        if (cell && row?.ParcelShipmentTracking && row?.ParcelShipmentTracking?.tracking_number)
          return <a href={cell} target={"_blank"} className="underline">{row?.ParcelShipmentTracking?.tracking_number}</a>
        else
          return row?.ParcelShipmentTracking?.tracking_number ? row?.ParcelShipmentTracking?.tracking_number : "";
      },
    },
    {
      dataField: "uuid",
      text: t("Parcel ID"),
      hidden: true,
    },
    {
      text: t("Tracking"),
      dataField: "ParcelShipmentTracking.tracking_number",
      hidden: true
    },
    {
      text: t("Boxes"),
      dataField: "parcel_children",
      formatter: (cell, row) => {
        return ParcelBoxes(cell, row);
      },
      csvFormatter: (cell, row) => {
        return ParcelBoxes(cell, row);
      },
    },
    {
      text: t("Destination"),
      dataField: "ParcelAddress.country",
    },
    {
      text: t('Customer'),
      dataField: "user.user_pin",
      formatter: (cell, row) => {
        return <div>
          <span className="d-flex">{cell}</span>
          <span className="font-12">{row.user?.name}</span></div>;
      },
      csvFormatter: (cell, row) => {
        return cell + " - " + row.user?.name;
      },
    },
    {
      text: t('Customer_Pin'),
      dataField: "user.user_pin",
      hidden: true,
      csvExport: false
    },
    {
      text: t('Customer_Name'),
      dataField: "user.name",
      hidden: true,
      csvExport: false
    },
    {
      text: t("Suite"),
      dataField: "suite",
      classes: "highlighted-text",
      formatter: (cell) => {
        return cell ? cell : '-';
      },
      csvFormatter: (cell, row) => {
        return cell ? cell : '-';
      },
      filter: textFilter({
        getFilter: (filter) => {
          parcelFilter = filter;
        }
      }),
    },
    {
      dataField: "weight_value",
      text: t("Sh_A_Wt"),
      sort: true,
      formatter: (cell, row) => {
        const actualWeight = ConsolidationActualWeight(row);
        return (
          actualWeight ?
            <div>
              <strong>{actualWeight?.toFixed(2)}</strong>
            </div>
            :
            <div>
              <strong>{0}</strong>
            </div>);
      },
      csvFormatter: (cell, row) => {
        const actualWeight = ConsolidationActualWeight(row);
        return actualWeight?.toFixed(2);
      },
    },
    {
      dataField: "dimension_width",
      text: t("Sh_Ch_Wt"),
      sort: true,
      formatter: (cell, row) => {
        const chargeableWeight = ConsolidationChargeableWeight(row);
        return (
          chargeableWeight ?
            <div>
              <strong>{chargeableWeight?.toFixed(2)}</strong>
            </div>
            :
            <div>
              <strong>{0}</strong>
            </div>);
      },
      csvFormatter: (cell, row) => {
        const chargeableWeight = ConsolidationChargeableWeight(row);
        return chargeableWeight?.toFixed(2);
      },
    },
    {
      dataField: "final_payable_amount",
      text: t("Original amount"),
      sort: true,
      formatter: (cell, row) => {
        return (
          <div>
            <strong>
              { cell && parseFloat(cell) !== 0 ?
                parseFloat(cell)?.toFixed(2) : 
                row?.ParcelPayment?.Transaction?.amount && parseFloat(row?.ParcelPayment?.Transaction?.amount) !== 0 ?
                    parseFloat(row?.ParcelPayment?.Transaction?.amount)?.toFixed(2) : 0
              }</strong>
          </div>
        );
      },
      csvFormatter: (cell, row) => {
        return cell && parseFloat(cell) !== 0 ?
        parseFloat(cell)?.toFixed(2) : 
        row?.ParcelPayment?.Transaction?.amount && parseFloat(row?.ParcelPayment?.Transaction?.amount) !== 0 ?
            parseFloat(row?.ParcelPayment?.Transaction?.amount)?.toFixed(2) : 0;
      },
    },
    {
      dataField: "ParcelPayment",
      text: t("Paid amount"),
      sort: true,
      formatter: (cell, row) => {
        return (
          <div>
            <strong>
              {cell?.Transaction?.transaction_method === "Wallet" ? 0 :
                row?.is_credit ? 0 :
                  cell?.Transaction?.amount && parseFloat(cell?.Transaction?.amount) !== 0 ?
                    parseFloat(cell?.Transaction?.amount)?.toFixed(2) :
                    cell?.charges ? cell?.charges : 0
              }</strong>
          </div>
        );
      },
      csvFormatter: (cell, row) => {
        return cell?.Transaction?.transaction_method === "Wallet" ? 0 :
        row?.is_credit ? 0 :
          cell?.Transaction?.amount && parseFloat(cell?.Transaction?.amount) !== 0 ?
            parseFloat(cell?.Transaction?.amount)?.toFixed(2) :
            cell?.charges ? cell?.charges : 0;
      },
    },
    {
      dataField: "ParcelPayment",
      text: t("Payment Method"),
      sort: true,
      formatter: (cell, row) => {
        return (
          <div>
            <strong>
              {cell?.Transaction?.transaction_method || ""}
              {cell?.Transaction?.transaction_method !== "Wallet"
                && cell?.Transaction?.wallet_amount && parseFloat(cell?.Transaction?.wallet_amount) !== 0 ?
                " - Wallet"
                : ""}
            </strong>
          </div>
        );
      },
      csvFormatter: (cell, row) => {
        return cell && cell?.Transaction ? `${cell?.Transaction?.transaction_method || ""} ${(cell?.Transaction?.transaction_method !== "Wallet"
          && cell?.Transaction?.wallet_amount && parseFloat(cell?.Transaction?.wallet_amount) !== 0 ?
          " - Wallet"
          : "")}` : "";
      },
    },
    {
      dataField: "ParcelPayment.Transaction",
      text: t("Promo_Value"),
      sort: true,
      formatter: (cell, row) => {
        const amount = cell?.amount && parseFloat(cell?.amount) !== 0 &&
          cell?.wallet_amount && parseFloat(cell?.wallet_amount) !== 0 ?
          parseFloat(cell?.amount) + parseFloat(cell?.wallet_amount)
          :
          cell?.amount && parseFloat(cell?.amount) !== 0 ?
            parseFloat(cell?.amount)
            :
            cell?.wallet_amount && parseFloat(cell?.wallet_amount) !== 0 ?
              parseFloat(cell?.wallet_amount) : parseFloat(cell?.original_amount)

        let totalAmount = (parseFloat(cell?.original_amount) - amount)?.toFixed(2);
        if(Math.sign(totalAmount) === -1){
          totalAmount = 0;
        }
        return (
          <div>
            <strong>
              {row?.is_credit ? 0 :
                cell?.promo_code_type && cell?.promo_code_type === "flat" ?
                  parseFloat(cell?.promo_code_value)?.toFixed(2)
                  : ((row?.user?.membership_plan_id > 1 || (cell?.promo_code && cell?.promo_code !== "")) && (cell?.original_amount !== 0 && amount && cell?.original_amount)) ?
                    totalAmount : 0
              }
            </strong>
          </div>
        );
      },
      csvFormatter: (cell, row) => {
        const amount = cell?.amount && parseFloat(cell?.amount) !== 0 &&
        cell?.wallet_amount && parseFloat(cell?.wallet_amount) !== 0 ?
        parseFloat(cell?.amount) + parseFloat(cell?.wallet_amount)
        :
        cell?.amount && parseFloat(cell?.amount) !== 0 ?
          parseFloat(cell?.amount)
          :
          cell?.wallet_amount && parseFloat(cell?.wallet_amount) !== 0 ?
            parseFloat(cell?.wallet_amount) : parseFloat(cell?.original_amount)

        let totalAmount = (parseFloat(cell?.original_amount) - amount)?.toFixed(2);
        if(Math.sign(totalAmount) === -1){
          totalAmount = 0;
        }
        return row?.is_credit ? 0 :
          cell?.promo_code_type && cell?.promo_code_type === "flat" ?
            parseFloat(cell?.promo_code_value)?.toFixed(2)
            : ((row?.user?.membership_plan_id > 1 || (cell?.promo_code && cell?.promo_code !== "")) && (cell?.original_amount !== 0 && amount && cell?.original_amount)) ?
              totalAmount : 0
      },
    },
    {
      dataField: "ParcelPayment",
      text: t("Shipment_Value"),
      sort: true,
      formatter: (cell, row) => {
        let totalValue = ShipmentValue(cell, row);
        if (totalValue > 0)
          return <a href={GLOBAL_CONSTANTS.INVOICE_DOWNLOAD_URL + row.uuid} target={"_blank"} className="underline"><strong>{totalValue?.toFixed(2)}</strong></a>
        else
          return totalValue;

      },
      csvFormatter: (cell, row) => {
        let totalValue = ShipmentValue(cell, row);
        return totalValue > 0 ? totalValue?.toFixed(2) : totalValue;
      },
    },
    {
      text: t('Status'),
      sort: true,
      dataField: "ParcelHasParcelStatus.ParcelStatus.name",
      formatter: (cell, row) => {
        return (cell)
          ? <div className={cell.toLowerCase().replace(/[^a-zA-Z ]/g, " ").split(' ').join('-')}>{t(cell)}</div>
          : ""
      },
      filter: textFilter({
        getFilter: (filter) => {
          statusFilter = filter;
        },
      }),
    },
    {
      text: t('Shipping'),
      dataField: "ShippingCompany.shipping_name",
      style: {
        "width": 110
      },
      formatter: (cell, row) => {
        if (cell && (row?.ParcelPayment?.service_identifier === "")) {
          return (
            <img width="100" height="80" src={logoUrl + row.ShippingCompany.logo} alt="shipping_company" />
          );
        } else if (cell && (row?.ParcelPayment?.service_identifier !== "")) {
          return (
            <div>
              <img width="100" height="80" src={logoUrl + row.ShippingCompany.logo} alt="shipping_company" />
              <span style={{ "font-size": "7px" }}><strong>{t(row?.ParcelPayment?.service_identifier ? row?.ParcelPayment?.service_identifier.replace(/_/g, " ") : '')}</strong></span>
            </div>
          );
        }
      },
      filter: textFilter({
        getFilter: (filter) => {
          shippingFilter = filter;
        }
      }),
      csvFormatter: (cell, row) => {
        return cell && row?.ParcelPayment?.service_identifier ? t(cell) + " - " + t(row?.ParcelPayment?.service_identifier.replace(/_/g, " ")) : cell;
      }
    },
  ];
  const rowClasses = (row, rowIndex) => {
    let table_class = 'custom-table-row dispatch-table'
    table_class += row.parcel_children.length <= 0 ? ' no-expand' : ''
    return table_class;
  };
  const expandRow = {
    className: (isExpanded, row, rowIndex) => {
      if (row.parcel_children.length <= 0)
        return 'no-children';
      return 'children';
    },
    renderer: (row) => {
      let parcelData = [];
      let parcelBox = [];
      let parcels = row.parcel_children;
      if (row.parcel_children.length <= 0)
        parcelData.push(row);


      if (parcels.length > 0) {
        parcels.forEach(element => {
          if (!element.uuid.match(/IC.*/) && !element.uuid.match(/BC.*/)) {
            parcelBox.push(element)
          }
          //row.parcel_children = parcelBox;
        });
        parcelData.push(row);
        parcels.forEach(element => {
          if (element.uuid.match(/IC.*/) || element.uuid.match(/BC.*/)) {
            parcelData.push(element)
          }
        });
      }

      parcelData = parcelData.sort((a, b) => (a.uuid > b.uuid) ? 1 : -1)
      const columns = [
        {
          dataField: "uuid",
          text: t("Parcel ID"),
          classes: "highlighted-text",
        },
        {
          text: t("Tracking"),
          dataField: "ParcelShipmentTracking.tracking_label",
          classes: "highlighted-text",
          csvExport: false,
          formatter: (cell, row) => {
            if (cell && row?.ParcelShipmentTracking && row?.ParcelShipmentTracking?.tracking_number)
              return <a href={cell} target={"_blank"} className="underline">{row?.ParcelShipmentTracking?.tracking_number}</a>
            else
              return row?.ParcelShipmentTracking?.tracking_number ? row?.ParcelShipmentTracking?.tracking_number : "";
          },
        },
        {
          text: t("No_Of_Parcels"),
          dataField: "parcel_children",
          formatter: (cell, row) => {
            if (cell.length > 0) {
              let parcelBox = cell;
              var parcelChild = []
              parcelBox.forEach(element => {
                if (!element.uuid.match(/IC.*/) && !element.uuid.match(/BC.*/)) {
                  parcelChild.push(element)
                }
              });
              return parcelChild.length;
            }
            else {
              return row.ParcelHasItems.length;
            }

          },
        },
        {
          text: t("NO_OF_ITEMS"),
          dataField: "parcel_children",
          formatter: (cell, row) => {
            if (cell.length > 0) {
              let parcelBox = cell;
              var parcelChild = []
              parcelBox.forEach(element => {
                if (!element.uuid.match(/IC.*/) && !element.uuid.match(/BC.*/)) {
                  parcelChild.push(element)
                }
              });
              let quantity = 0;
              parcelChild.forEach(parcelItems => {
                parcelItems.ParcelHasItems.forEach(parcelItem => {
                  quantity += parcelItem.quantity
                });
              });
              return quantity;
            }
            else {
              let quantity = 0
              row.ParcelHasItems.forEach(parcelItem => {
                quantity += parcelItem.quantity
              });
              return quantity;
            }

          },
          csvFormatter: (cell, row) => {
            if (cell.length > 0) {
              let parcelBox = cell;
              var parcelChild = []
              parcelBox.forEach(element => {
                if (!element.uuid.match(/IC.*/) && !element.uuid.match(/BC.*/)) {
                  parcelChild.push(element)
                }
              });
              return parcelChild.length;
            }
            else
              return 0;
          },
        },
        {
          dataField: 'weight_unit',
          text: t('Actual_Weight'),
          formatter: (cell, row) => {
            return (
              row?.weight_value ?
                <div>
                  <strong>{row.weight_value}</strong> {cell}
                </div>
                :
                <div>
                  <strong>{0}</strong>
                </div>);
          },
        },
        {
          dataField: "weight_value",
          text: t("Chargeable weight"),
          formatter: (cell, row) => {
            const actualWeight = row.weight_value;
            const VolumeWeight = GetVolumeWeight(row);
            const chargeableWeight = ChargeableWeight(VolumeWeight, actualWeight);
            return (
              chargeableWeight ?
                <div>
                  <strong>{chargeableWeight?.toFixed(2)}</strong> {row.weight_unit}
                </div>
                :
                <div>
                  <strong>{0}</strong>
                </div>);
          },
        },
        {
          dataField: 'dimension_length',
          text: t('Dimensions'),
          formatter: (cell, row) => {
            const w = row.dimension_width;
            const h = row.dimension_height;

            return cell ? cell + " x " + w + " x " + h + "  " + row.dimensions_unit : '-';
          },
        }, {
          dataField: "is_credit",
          text: t("Credited"),
          formatter: (cell, row) => {
            return cell ?
              <span className="text-success">
                {t("yes")}
              </span>
              :
              <span className="text-danger">
                {t("no")}
              </span>;
          },
        }, {
          dataField: "ParcelPayment",
          text: t("Wallet Amount"),
          formatter: (cell, row) => {
            return cell?.Transaction?.wallet_amount && parseFloat(cell?.Transaction?.wallet_amount) !== 0 ?
              parseFloat(cell?.Transaction?.wallet_amount)?.toFixed(2) : 0
          },
        }
      ]
      return (
        <BootstrapTable
          keyField="id"
          data={parcelData}
          expandRow={expandRowChild}
          columns={columns}
          wrapperClasses="inner-table my-3 mx-4 "
          headerClasses="header-class"
          bordered={false}
          noDataIndication={t("No_Record")}
          rowClasses="inner-table-row"
          condensed={true} />
      )
    },
    showExpandColumn: true,
    expandByColumnOnly: false,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      return "";
    },
    expandColumnRenderer: ({ expanded, rowKey, row }) => {
      if (expanded) {
        return <ChevronUp color="#2D3748" size={10} className="mx-2 my-3 arrow-icon" />;
      }
      return <ChevronDown color="#2D3748" size={10} className="mx-2 my-3 arrow-icon" />;
    },
  };

  const expandRowChild = {
    className: (isExpanded, row, rowIndex) => {
      if (row.parcel_children.length <= 0)
        return 'no-children';
      return 'children';
    },
    renderer: (row) => {
      if (row.parcel_children.length <= 0)
        return (
          <Row className="expanding-row px-4 py-4">
            <Col xs={12} sm={12} md={12} lg={5}>
              {t('No_Record')}
            </Col>
          </Row>);

      let parcelBox = row.parcel_children;
      var parcelChild = []
      parcelBox.forEach(element => {
        if (!element.uuid.match(/IC.*/) && !element.uuid.match(/BC.*/)) {
          parcelChild.push(element)
        }
      });
      const columns = [
        {
          dataField: "uuid",
          text: t("Parcel ID"),
          classes: "highlighted-text",
        },
        {
          text: t("NO_OF_ITEMS"),
          dataField: "ParcelHasItems",
          formatter: (cell, row) => {
            let quantity = 0
            cell.forEach(parcelItem => {
              quantity += parcelItem.quantity
            });
            return quantity
          },
        },
        {
          dataField: 'weight_unit',
          text: t('Actual_Weight'),
          formatter: (cell, row) => {
            return (
              row?.weight_value ?
                <div>
                  <strong>{row?.weight_value}</strong> {cell}
                </div>
                :
                <div>
                  <strong>{0}</strong>
                </div>);
          },
        },
        {
          dataField: "weight_value",
          text: t("Chargeable weight"),
          formatter: (cell, row) => {
            const actualWeight = row.weight_value;
            const VolumeWeight = GetVolumeWeight(row);
            const chargeableWeight = ChargeableWeight(VolumeWeight, actualWeight);
            return (
              chargeableWeight ?
                <div>
                  <strong>{chargeableWeight?.toFixed(2)}</strong> {row.weight_unit}
                </div>
                :
                <div>
                  <strong>{0}</strong>
                </div>);
          },
        },
        {
          dataField: 'dimension_length',
          text: t('Dimensions'),
          formatter: (cell, row) => {
            const w = row.dimension_width;
            const h = row.dimension_height;

            return cell ? cell + " x " + w + " x " + h + "  " + row.dimensions_unit : '-';
          },
        },
        {
          dataField: "is_credit",
          text: t("Credited"),
          formatter: (cell, row) => {
            return cell ?
              <span className="text-success">
                {t("yes")}
              </span>
              :
              <span className="text-danger">
                {t("no")}
              </span>;
          },
        }
      ]
      return (
        <BootstrapTable
          keyField="id"
          data={parcelChild}
          columns={columns}
          wrapperClasses="child-table my-3 mx-4 "
          headerClasses="header-class"
          bordered={true}
          noDataIndication={t("No_Record")}
          rowClasses="inner-table-row"
          condensed={false} />
      )
    },
    showExpandColumn: true,
    expandByColumnOnly: false,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      return "";
    },
    expandColumnRenderer: ({ expanded, rowKey, row }) => {
      if (expanded) {
        return <ChevronUp color="#2D3748" size={10} className="mx-2 my-3 arrow-icon" />;
      }
      return <ChevronDown color="#2D3748" size={10} className="mx-2 my-3 arrow-icon" />;
    },
  };

  const options = {
    sizePerPage: GLOBAL_CONSTANTS.SIZE_PER_PAGE,
    hideSizePerPage: false,
    hidePageListOnlyOnePage: true,
    sizePerPageOptionRenderer,
    showTotal: true,
    pageListRenderer,
    paginationTotalRenderer: CustomTotal,
  };

  const { ExportCSVButton } = CSVExport;

  return (
    <div className="table-responsive">
      {Columns &&
        <ToolkitProvider
          keyField="uuid"
          data={props.dispatchedData}
          columns={Columns}
          search={{ searchFormatted: true }}
          exportCSV={{
            fileName: "DispatchedTable.csv",
            noAutoBOM: false,
            exportAll: false,
            onlyExportFiltered: true,
          }}
        >
          {
            props => (
              <div className="table-container my-4">
                <Row className="search dispatch-search mx-2">
                  <Col xs={12} sm={12} md={12} lg={9}>
                    <Row>
                      <Col xs={12} sm={6} md={6} lg={3} className="px-4">
                        <SelectFilterColums preFilteredRows={props.baseProps.data} id="ShippingCompany" subId="shipping_name" text={t("Shipping")} {...props.searchProps} onFilterClick={onFilter} />
                      </Col>
                      {/*<Col xs={12} sm={6} md={6} lg={3}>
                        <SelectFilterColums preFilteredRows={props.baseProps.data} id="user" subId="name" text={t("Customer")} { ...props.searchProps }  onFilterClick={onFilter}/>
                      </Col>*/}
                      {/*<Col xs={12} sm={6} md={6} lg={3}>
                        <SelectFilterColums preFilteredRows={props.baseProps.data} id="suite" text={t("Suite")} { ...props.searchProps }  onFilterClick={onFilter}/>
                      </Col>*/}
                      <Col xs={12} sm={6} md={6} lg={3}>
                        <SelectFilterColums
                          preFilteredRows={props.baseProps.data}
                          id="ParcelHasParcelStatus"
                          subId="ParcelStatus"
                          otherSubId="name"
                          text={t("Status")}
                          {...props.searchProps}
                          onFilterClick={onFilter}
                          table="dispatch"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={3} className="px-4">
                    <SearchBar {...props.searchProps} />
                  </Col>
                  <Col className="d-none">
                    <ExportCSVButton {...props.csvProps} id="dispatchedExportButton">
                      Export
                    </ExportCSVButton>
                  </Col>
                </Row>
                <Row className="table-data my-3">
                  <Col>
                    <BootstrapTable
                      rowClasses={rowClasses}
                      expandRow={expandRow}
                      {...props.baseProps}
                      wrapperClasses="table-wrapper dispatch-main my-3 "
                      headerClasses="header-class"
                      bordered={false}
                      noDataIndication={t("No_Record")}
                      filter={filterFactory()}
                      condensed={true}
                      pagination={paginationFactory(options)}
                      sort={{ dataField: 'ParcelHasParcelStatus.created_at', order: 'desc' }}
                    />
                  </Col>
                </Row>
              </div>
            )
          }
        </ToolkitProvider>
      }
    </div>
  );
}

export default DispatchedTable;
