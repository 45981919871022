import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Calendar4, ChevronDown } from "react-bootstrap-icons";
import moment from "moment";

function DateRangePickerCustom(props) {
    const { t } = useTranslation();
    const dateRangePickerRef = useRef(null);
    const [rangeStyle, setRangeStyle] = useState("custom-empty-date-range-picker");

    const focusOnDateRangePicker = () => {
        dateRangePickerRef.current.ref.focus();
    };

    const handleApply = (event, picker) => {
        picker.element.val(picker.startDate.format("DD MMM YYYY") + " - " + picker.endDate.format("DD MMM YYYY"));
        props.setStartDate(picker.startDate);
        props.setEndDate(picker.endDate);
        setRangeStyle("custom-date-range-picker");
    };
    const handleCancel = (event, picker) => {
        props.setStartDate("2020-01-01");
        props.setEndDate(moment().format("YYYY-MM-DD"));
        picker.element.val("");
        setRangeStyle("custom-empty-date-range-picker");
    };
    return (
        <div className={`d-flex align-items-center white-background p-2 px-3 h-100 custom-date-range-picker ${rangeStyle}`}>
            <Calendar4 className="opacity-2 img-width" onClick={focusOnDateRangePicker} />
            <DateRangePicker
                initialSettings={{
                    autoUpdateInput: false,
                    locale: {
                        cancelLabel: "Clear",
                    },
                    startDate: moment(props.startDate), 
                    endDate:  moment(props.endDate)
                }}
                onApply={handleApply}
                onCancel={handleCancel}
                ref={dateRangePickerRef}
            >
                <input type="text" className="mr-2 ml-2 text-center" placeholder={t("Date")}
                value={moment(props.startDate).format("DD MMM YYYY")+" - "+moment(props.endDate).format("DD MMM YYYY")} />
            </DateRangePicker>
            <ChevronDown className="img-width" onClick={focusOnDateRangePicker} />
        </div>
    );
}

export default DateRangePickerCustom;
