import React from "react";
import { useTranslation } from "react-i18next";
import {Container } from "react-bootstrap";
import Sidebar from "./Sidebar/Sidebar";


const MainContainer = ({ children }) => {

  const { i18n } = useTranslation();
  const mainContainerClasses = `main-container ${i18n.language}`;

  return (
    <>
      <Sidebar />
      <Container fluid className={mainContainerClasses + " pl-0 pr-0 home_content container-fluid"}>
        {children}
      </Container>

    </>
  );
};

export default MainContainer;
