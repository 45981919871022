import React from "react";
import Logo from "../../../assets/parcel_hub_logo.svg";
import dashboard from "../../../assets/side-bar-icons/dashboard.svg";
import parcels from "../../../assets/side-bar-icons/parcels.svg";
import consolidation from "../../../assets/side-bar-icons/consolidation.svg";
import drop from "../../../assets/side-bar-icons/drop.svg";
import pick from "../../../assets/side-bar-icons/pick.svg";
import dispatch from "../../../assets/side-bar-icons/dispatch_ic.svg";
import suites from "../../../assets/side-bar-icons/suites.svg";
import users from "../../../assets/side-bar-icons/users.svg";
import promo_codes from "../../../assets/side-bar-icons/promo_code.svg";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import axios from "axios";
import * as config from "config/config";
import { API_CONSTANTS } from "constants/constants";

const Sidebar = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const [inProcessCount, setInProcessCount] = React.useState('');
    var token = localStorage.getItem("accessToken");
    var userWarehouse = localStorage.getItem("userWarehouse");

    useEffect(() => {
      const tokenConfig = {
          headers: { Authorization: `Bearer ${token}` },
      };
      
      async function fetchData() {
          const parcelsResponse = await axios.get(config.apiUrl + API_CONSTANTS.IN_PROCESS_PARCELS_COUNT+ "?warehouse_id="+userWarehouse, tokenConfig);
          if(parcelsResponse.status === 200 && parcelsResponse?.data?.payload?.getAllParcels)
            setInProcessCount(parcelsResponse?.data?.payload?.getAllParcels[0].count)
      }
  
      fetchData();
  }, []);

    return (
        <div className="sidebar">
            <div className="logo_content d-flex justify-content-center align-items-center">
                <Link to="/dashboard" style={{ textDecoration: "none" }}>
                    <div className="logo" style={{ textAlign: "center" }}>
                        <img
                            style={{ textAlign: "center", marginLeft: "5px" }}
                            className="icon"
                            src={Logo}
                            alt="logo"
                        />
                        <div className="logo_name">{t("Parcel Hub")}</div>
                    </div>
                </Link>
            </div>
            <ul className="nav_list">
                <li className={location.pathname === "/dashboard"? "active-sidebar-link sidebar-li" : "sidebar-li"}>
                    <Link to="/dashboard">
                        <img className="icon" src={dashboard} alt="dash" />
                        <span className="links_name">{t("Dashboard")}</span>
                    </Link>
                    <span className="tooltip">{t("Dashboard")}</span>
                </li>
                <li
                    className={`${location.pathname === "/parcels" ? "active-sidebar-link sidebar-li" : "sidebar-li"} ${
                        location.pathname === "/" ? "top-right-radius" : ""
                    }`}
                >
                    <Link to="/parcels">
                        <img className="icon sidebar-svg" src={parcels} alt="" />
                        <span className="links_name">{t("Parcels")}</span>
                    </Link>

                    <span className="tooltip">{t("Parcels")}</span>
                </li>
                <li
                    className={`${location.pathname === "/inprocess" ? "active-sidebar-link sidebar-li" : "sidebar-li"} ${
                        location.pathname === "/inprocess" ? "top-right-radius" : ""
                    }`}
                >
                    <Link to="/inprocess">
                        <img className="icon sidebar-svg" src={parcels} alt="" />
                        <span className="links_name">{t("In Process")} 
                            {inProcessCount > 0 && <span className="red"> ({inProcessCount})</span> }
                        </span>
                    </Link>

                    <span className="tooltip">{t("In Process")}</span>
                </li>
                <li className={location.pathname === "/users" ? "active-sidebar-link sidebar-li" : "sidebar-li"}>
                    <Link to="/users">
                        <img className="icon sidebar-svg" src={users} alt="users" />
                        <span className="links_name">{t("Users")}</span>
                    </Link>
                    <span className="tooltip">{t("Users")}</span>
                </li>
                <li className={location.pathname === "/suites" ? "active-sidebar-link sidebar-li" : "sidebar-li"}>
                    <Link to="/suites">
                        <img className="icon sidebar-svg" src={suites} alt="suites" />
                        <span className="links_name">{t("Suites")}</span>
                    </Link>
                    <span className="tooltip">{t("Suites")}</span>
                </li>
                <li className={location.pathname === "/consolidated" ? "active-sidebar-link sidebar-li" : "sidebar-li"}>
                    <Link to="/consolidated">
                        <img className="icon sidebar-svg" src={consolidation} alt="consolidate" />
                        <span className="links_name">{t("Consolidation")}</span>
                    </Link>
                    <span className="tooltip">{t("Consolidation")}</span>
                </li>
                <li className={location.pathname === "/dispatched" ? "active-sidebar-link sidebar-li" : "sidebar-li"}>
                    <Link to="/dispatched">
                        <img className="icon sidebar-svg" src={dispatch} alt="dispatch" />
                        <span className="links_name">{t("Dispatch")}</span>
                    </Link>
                    <span className="tooltip">{t("Dispatch")}</span>
                </li>
                <li className={location.pathname === "/drop" ? "active-sidebar-link sidebar-li" : "sidebar-li"}>
                    <Link to="/drop">
                        <img className="icon sidebar-svg" src={drop} alt="drop" />
                        <span className="links_name">{t("Drop")}</span>
                    </Link>
                    <span className="tooltip">{t("Drop")}</span>
                </li>
                <li className={location.pathname === "/pick" ? "active-sidebar-link sidebar-li" : "sidebar-li"}>
                    <Link to="/pick">
                        <img className="icon sidebar-svg" src={pick} alt="pick" />
                        <span className="links_name">{t("Pick")}</span>
                    </Link>
                    <span className="tooltip">{t("Pick")}</span>
                </li>
               
                <li className={location.pathname === "/promo-codes" ? "active-sidebar-link sidebar-li" : "sidebar-li"}>
                    <Link to="/promo-codes">
                        <img className="icon sidebar-svg" src={promo_codes} alt="promo" />
                        <span className="links_name">{"Promo Codes"}</span>
                    </Link>
                    <span className="tooltip">{"Promo Codes"}</span>
                </li>
                {/*<li className={location.pathname === "/promo-codes" ? "active-sidebar-link sidebar-li" : "sidebar-li"}>
                    <Link to="/promo-codes">
                        <img className="icon sidebar-svg" src={shipments} alt="shipments" />
                        <span className="links_name">{"Shipments"}</span>
                    </Link>
                    <span className="tooltip">{"Shipments"}</span>
                </li>*/}
            </ul>
        </div>
    );
};

export default Sidebar;
