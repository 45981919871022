import React, { useState, useEffect } from "react";
import { FaChevronDown } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { NavDropdown } from 'react-bootstrap';
import { useAuth } from "../../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { API_CONSTANTS, GLOBAL_CONSTANTS } from "constants/constants";
import axios from "axios";
import * as config from "config/config";
import Flag from "react-world-flags";

const Navbar = () => {
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const { logout } = useAuth();
    var token = localStorage.getItem("accessToken");
    var userWarehouse = localStorage.getItem("userWarehouse");
    const [allWarehouses, setAllWarehouses] = useState();
    const [activeWarehouse, setActiveWarehouse] = useState(GLOBAL_CONSTANTS.DEFAULT_WAREHOUSE);

    useEffect(() => {
        const tokenConfig = {
            headers: { Authorization: `Bearer ${token}` },
        };
        async function fetchData() {
            const WarehouseResponse = await axios.get(
                config.apiUrl + API_CONSTANTS.GET_ALL_WAREHOUSES,
                tokenConfig
            );
            setAllWarehouses(WarehouseResponse?.data?.payload);
            const warehouse = WarehouseResponse?.data?.payload.filter(uw => uw.id === parseInt(userWarehouse));
            if(warehouse && warehouse.length > 0) 
                setActiveWarehouse(warehouse[0])
        }

        fetchData();
    }, [userWarehouse]);

    const handleLogout = () => {
        logout();
        window.location.replace("/home");
    };
    const handleMenuButtonClick = () => {
        const menuBar = document.querySelector(".sidebar");
        menuBar.classList.toggle("active");
    };

    return (
        <div
            className={`nav-bar dash_user p-l-30 p-r-50 py-3 d-flex justify-content-between align-items-center white-background ${
                location.pathname !== "/" ? "shadow-none" : "nav-bar-dashboard"
            }`}
        >
            <div className="d-flex align-items-center">
                <i onClick={handleMenuButtonClick} className="bx bx-menu pr-4" id="menuBtn"></i>
            </div>

            <div className="d-flex justify-content-end align-items-center white-background username-color px-2">
                <NavDropdown title={t("BI Admin")} id="basic-nav-dropdown" className="username-color">
                    <NavDropdown.Item onClick={() => handleLogout()}>{t('Logout')}</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown 
					title={ 
						<>
							<Flag className="d-inline main-flag flag mx-2" code={activeWarehouse?.country} />
							{activeWarehouse?.name || t("")}
						</>
					} 
					id="basic-nav-dropdown" 
					className="username-color"
					disabled={allWarehouses?.length <= 1}
				>
					{allWarehouses?.filter((uw) => uw.id !== parseInt(userWarehouse))?.map((uw) =>
						<NavDropdown.Item
							key={`${uw?.name}-${uw?.id}`}
							onClick={async () => {
                                    setActiveWarehouse({
                                        "id": uw?.id,
                                        "name": uw?.name,
                                        "country": uw?.country
                                    })
									localStorage.setItem("userWarehouse", uw.id)
                                    window.location.reload();
								}
							}>
							{uw?.name}
                            <Flag className="d-inline flag mx-2" code={uw?.country} />
						</NavDropdown.Item>
					)}
				</NavDropdown>
            </div>
        </div>
    );
};

export default Navbar;
