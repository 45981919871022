import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import { useTranslation } from "react-i18next";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Col, Row  } from "react-bootstrap";
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import { SearchBar, SelectFilterColums } from "components/Tables/Columns";
import { textFilter } from 'react-bootstrap-table2-filter';
import moment from "moment";
import { ChevronUp, ChevronDown } from 'react-bootstrap-icons';
import pageListRenderer from '../Tables/PageListRenderer';
import sizePerPageOptionRenderer from '../Tables/SizePerPageOptionRenderer';
import CustomTotal from "../Tables/CustomTotal";
import { logoUrl } from "config/config";
import { GLOBAL_CONSTANTS } from "constants/constants";

const selectOptions = {
  0: 'Empty',
  1: 'Busy'
};
let suiteFilter,itemFilter, userCountryFilter;

function SuitesTable(props) {
  const { t } = useTranslation();
  const onFilter = (value, id) => {
    const data = Object.keys(selectOptions).find(key => selectOptions[key] === value)

    if(!value)
      value = '';
    if(id === 'suite')
      suiteFilter(value);
    else if(id === 'active'){
      itemFilter(data);
    }
    else if(id === 'user_suite')
      userCountryFilter(value);
  };

  function setExpiry(cell, row){
    var new_date = moment(cell).utc().format("DD MMM yyyy");
    let allotedDays = 0;
    if(row.user_suite?.allotted_days)
      allotedDays = row.user_suite?.allotted_days;

    if(cell)
      new_date = moment(moment(cell).add(allotedDays, 'd')).utc().format("DD MMM yyyy");
    
    return new_date;
  }

  function getParcelsItems(cell){
    let quantity = 0;
      cell.forEach(ParcelSuites => {
        if(ParcelSuites.parcel){
          ParcelSuites.parcel.ParcelHasItems.forEach(parcelItem => {
            quantity += parcelItem.quantity
          });
        }
      });
      return quantity
  }

  function setStatus(cell, row) {
    return row.ParcelSuites.length > 0 ? (
      <span className="status">
        {t('Busy')}
      </span>
    ) : cell === 1 ? (
      <span className="expired">
        {t('Expired')}
      </span>
    ) :  (
      <span className="empty-status">
        {t('Empty')}
      </span>
    );
  }

  function getParcelStorageDays(ParcelSuite) {
    //Difference in number of days
    var given = moment(ParcelSuite.created_at, "YYYY-MM-DD");
    var current = moment().startOf('day');
    return  Math.abs(
      moment(given, 'YYYY-MM-DD')
        .startOf('day')
        .diff(moment(current, 'YYYY-MM-DD').startOf('day'), 'days')
    ) + 1;
  }
 const columns = [
  {
    dataField: "created_at",
    text: t('Expiry'),
    sort: true,
    formatter: (cell, row) => {
      return setExpiry(cell, row)
    },
    sortValue: (cell, row) => {
      return setExpiry(cell, row)
    },
    csvFormatter: (cell, row) => {
      return setExpiry(cell, row)
    },
  },
  {
    text: t('Suite_Id'),
    dataField: "suite",
    classes: "highlighted-text",
    filter: textFilter({
      getFilter: (filter) => {
        suiteFilter = filter;
      }
    })
  },
  {
    text: t('Parcels'),
    dataField: "ParcelSuites.length",
    sort: true,
  },
  {
    text: t('Items'),
    dataField: "ParcelSuites",
    formatter: (cell) => {
      return getParcelsItems(cell)
    },
    csvFormatter: (cell, row) => {
      return getParcelsItems(cell)
    },
  },
  {
    text: t('User_Id'),
    dataField: "user_suite.user.country",
    formatter: (cell, row) => {
      return <div>
        <span className="d-flex">{row.user_suite?.user?.user_pin}</span>
        <span className="font-12">{row.user_suite?.user?.name}</span></div>;
    },
    csvFormatter: (cell, row) => {
        return cell?row.user_suite?.user?.user_pin +" ,"+ row.user_suite?.user?.name:"";
    },
  },
  {
    text: t('Customer_Pin'),
    dataField: "user_suite.user.user_pin",
    hidden:true,
    csvExport:false
  },
  {
    text: t('Customer_Name'),
    dataField: "user_suite.user.name",
    hidden:true,
    csvExport:false
  },
  {
    text: t('Status'),
    dataField: "user_suite.is_expired",
    sort: true,
    formatter: (cell, row) => {
      return setStatus(cell, row)
    },
    sortValue: (cell, row) => {
      return setStatus(cell, row)
    },
    filter: selectFilter({
      options: selectOptions,
      getFilter: (filter) => {
        itemFilter = filter;
      },
    }),
    csvFormatter: (cell, row) => {
      return row.ParcelSuites.length > 0 
      ? t('Busy')
      : cell === 1 
      ? t('Expired')
      : t('Empty')
    },
  },
  {
    text: t('Parcel_Detail'),
    dataField: "id",
    hidden: true,
    csvText: t('Parcel_Detail'),
    csvFormatter: (cell, row) => {
      let parcelData = '';
      row.ParcelSuites.forEach((ParcelSuite, index) => {
        if(ParcelSuite.parcel){
          const totalStorageDays = getParcelStorageDays(ParcelSuite)+",";
          const uuid = ParcelSuite?.parcel?.uuid?ParcelSuite.parcel.uuid+",": "";
          const parcelQuantity = ParcelSuite?.parcel?.ParcelHasItems?ParcelSuite?.parcel?.ParcelHasItems.length+",": 0;
          const companyName= ParcelSuite?.parcel?.ShippingCompany?ParcelSuite.parcel.ShippingCompany.shipping_name:"";
          const parcelDestination = ParcelSuite?.parcel?.ParcelAddress?ParcelSuite.parcel.ParcelAddress.country+",":""
          parcelData += uuid+parcelQuantity+totalStorageDays+parcelDestination+companyName;
          if(index < row.ParcelSuites.length-1)
            parcelData +=" :: "
        }
      });
      return parcelData;
    },
  },
  
];

const expandRow = {
  renderer: row => {
    const totalParcels = row.ParcelSuites.length;
    return(
    <Row className='expanding-row pt-2 suites'>
      <Col xs={12} sm={12} md={12} lg={12}>
        <div className="heading px-3 ">{totalParcels} { totalParcels > 0 ?t('Parcels_Suite'): t('Parcel_Suite')}</div>
        {row.ParcelSuites && row.ParcelSuites.length > 0 && <Row className="expand-data px-2 ">
          <Col xs={12} sm={12} md={12} lg={3} className="value">
          <span className="pr-3 pl-2"><strong>{t('No')}</strong></span><strong>{t('Parcel_Uuid')}</strong></Col>
          <Col xs={12} sm={12} md={12} lg={2} className="value suites-expand"><strong>{t('Item')}</strong></Col>
          <Col xs={12} sm={12} md={12} lg={2} className="value suites-expand"><strong>{t('Storage_Days')}</strong></Col>
          <Col xs={12} sm={12} md={12} lg={2} className="value suites-expand"><strong>{t('Destination')}</strong></Col>
          <Col xs={12} sm={12} md={12} lg={3} className="value"><strong>{t('Shipping_Company_Logo')}</strong></Col>
        </Row>}
        {row.ParcelSuites?.map((ParcelSuite, index) => {
        let parcelQuantity =  0;
        if(ParcelSuite?.parcel?.ParcelHasItems) {
          ParcelSuite.parcel.ParcelHasItems.forEach(parcelItem => {
            parcelQuantity += parcelItem.quantity
          });
        }
        const parcelDestination = ParcelSuite?.parcel?.ParcelAddress?ParcelSuite.parcel.ParcelAddress.country:""
        const totalStorageDays = getParcelStorageDays(ParcelSuite);
        return (
        <Row className="expand-data px-2 ">
          <Col xs={12} sm={12} md={12} lg={3} className="value">
          <span className="pr-3 pl-2">{index+1}</span>
          <span className="highlighted-text">{ParcelSuite?.parcel?.uuid}</span></Col>
          <Col xs={12} sm={12} md={12} lg={2} className="value suites-expand ">{parcelQuantity}</Col>
          <Col xs={12} sm={12} md={12} lg={2} className="value suites-expand">{totalStorageDays}</Col>
          <Col xs={12} sm={12} md={12} lg={2} className="value suites-expand">{parcelDestination}</Col>
          <Col xs={12} sm={12} md={12} lg={3} className="value"><img width="90" height="50" src={ParcelSuite?.parcel?.ShippingCompany?.logo?logoUrl + ParcelSuite?.parcel?.ShippingCompany.logo: ParcelSuite?.parcel?.ShippingCompany.name} alt="shipping_company" /></Col>
        </Row>
      )}
    )}
      </Col>
    </Row>
  )},
  showExpandColumn: true,
  expandByColumnOnly: true,
  expandHeaderColumnRenderer: ({ isAnyExpands }) => {
    return '';
  },
  expandColumnRenderer: ({ expanded }) => {
    if (expanded) {
      return (
        <ChevronUp color="#2D3748" size={10} className="mx-2 my-3 arrow-icon" />
      );
    }
    return (
      <ChevronDown color="#2D3748" size={10} className="mx-2 my-3 arrow-icon" />
    );
  }
};

  const options = {
    sizePerPage: GLOBAL_CONSTANTS.SIZE_PER_PAGE,
    hideSizePerPage: false,
    hidePageListOnlyOnePage: true,
    sizePerPageOptionRenderer,
    showTotal: true,
    pageListRenderer,
    paginationTotalRenderer: CustomTotal,
  };
  
  const { ExportCSVButton } = CSVExport;

  return (
    <div className="table-responsive">
      {columns &&
        <ToolkitProvider
          keyField="id"
          data={ props.suitesData }
          columns={ columns }
          search={ { searchFormatted: true } }
          exportCSV={{
                        fileName: "SuitesTable.csv",
                        noAutoBOM: false,
                        exportAll: false,
                        onlyExportFiltered: true,
          }}
        >
          {
            props => (
              <div className="table-container my-4">
                <Row className="search mx-2 suites-search">
                  <Col xs={12} sm={12} md={12} lg={9}>
                    <Row>
                      {/*<Col xs={12} sm={6} md={6} lg={3} className="px-4">
                        <SelectFilterColums 
                          preFilteredRows={props.baseProps.data}
                          id="suite" 
                          subId="suite" 
                          text={t("Suite_Id")} 
                          { ...props.searchProps } 
                          onFilterClick={onFilter} />
                      </Col>*/}
                      <Col xs={12} sm={6} md={6} lg={3}  className="px-4">
                      <SelectFilterColums 
                        preFilteredRows={props.baseProps.data} 
                        id="active" 
                        text={t("Status")} 
                        { ...props.searchProps } 
                        onFilterClick={onFilter} 
                        table="suite" />
                      </Col>
                      {/*<Col xs={12} sm={6} md={6} lg={3}>
                      <SelectFilterColums 
                        preFilteredRows={props.baseProps.data} 
                        id="user_suite" 
                        subId="user" 
                        otherSubId="country"  
                        text={t("Country")} 
                        { ...props.searchProps }  
                        onFilterClick={onFilter} />
                      </Col>*/}
                      <Col xs={12} sm={6} md={6} lg={3}></Col>
                    </Row>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={3}  className="px-4">
                    <SearchBar { ...props.searchProps } />
                  </Col>
                  <Col className="d-none">
                    <ExportCSVButton {...props.csvProps} id="suitesExportButton">
                        Export
                    </ExportCSVButton>
                  </Col>
                </Row>
                <Row className="table-data my-3">
                  <Col>
                    <BootstrapTable
                      expandRow={ expandRow }
                      { ...props.baseProps }
                      wrapperClasses="table-wrapper my-3"
                      headerClasses="header-class"
                      bordered={ false }
                      noDataIndication={t("No_Record")}
                      filter={ filterFactory() }
                      rowClasses="custom-table-row"
                      condensed={true}
                      pagination={ paginationFactory(options) }
                      sort={ { dataField: 'created_at', order: 'desc' } }
                    />
                  </Col>
                </Row>
              </div>
            )
          }
        </ToolkitProvider>
      }
    </div>
  );
}

export default SuitesTable;
