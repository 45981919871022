import React, {Suspense} from "react";
import ReactDOM from 'react-dom';
import './index.css';
import './i18n';
import App from './App';
import {AuthProvider} from "./contexts/AuthContext";

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<div>Loading ...</div>}> 
      <AuthProvider>
        <App />
      </AuthProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);


