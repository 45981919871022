import React from "react";
import { useTranslation } from "react-i18next";
import { ChakraProvider } from "@chakra-ui/react";
import {Container } from "react-bootstrap";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LayoutContainer from "Pages/Layout/Container";
import Dashboard from "Pages/Dashboard";
import Parcels from "Pages/Parcels";
import Users from "Pages/Users";
import Consolidated from "Pages/Consolidated";
import Suites from "Pages/Suites";
import PromoCodes from "Pages/PromoCodes";
import Dispatched from "Pages/Dispatched";
import Drop from "Pages/Drop";
import Pick from "Pages/Pick";
import Login from "Pages/Login";
import "./assets/css/index.scss";
import Navbar from "components/Navbar/Navbar";
import Cookies from 'js-cookie';
import { useAuth } from "./contexts/AuthContext";
import WithoutLogin from "./Pages/Layout/WithoutLogin";
import InProcessParcels from "Pages/InProcessParcels";

require("dotenv").config();

function App() {
    const { i18n } = useTranslation();
    const { authToken } = useAuth();
    let loginClass = '';
    if(!authToken)
        loginClass = 'login'

    const mainContainerClasses = `main-container ${i18n.language} ${loginClass}`;
    
    return (
        <Router>
            <ChakraProvider>
                { authToken !== "" && authToken !== undefined ?
                <LayoutContainer fluid className={mainContainerClasses}><Navbar />
                    <Switch>
                        <Route path="/users" component={Users} exact />
                        <Route path="/parcels/:type?" component={Parcels} exact />
                        <Route path="/consolidated" component={Consolidated} exact />
                        <Route path="/suites" component={Suites} exact />
                        <Route path="/promo-codes" component={PromoCodes} exact />
                        <Route path="/dispatched" component={Dispatched} exact />
                        <Route path="/drop" component={Drop} exact />
                        <Route path="/pick" component={Pick} exact />
                        <Route path="/inprocess" component={InProcessParcels} exact />
                        <Route path="/dashboard" component={Dashboard} exact />
                        <Route path="/" component={Dashboard} exact />
                    </Switch></LayoutContainer>
                :  
                <Container fluid className={mainContainerClasses}>
                    <WithoutLogin />
                </Container>
            }
                
            </ChakraProvider>
        </Router>
    );
}

export default App;
