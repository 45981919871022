import React from "react";

function ParcelBoxes(cell, row) {
    let quantity = 0;
    if (row.uuid.match(/IC.*/) || row.uuid.match(/BC.*/)) {
        quantity = 1;
    }
    if(cell && cell.length > 0){
        cell.forEach(parcelItem => {
            if(parcelItem?.parcel_children.length > 0)
                quantity += 1
        });
    }
    return quantity > 0? quantity: "";
};
export default ParcelBoxes;
