import React, { memo } from "react";
import { Bar } from "react-chartjs-2";
import AE from "assets/country/AE.svg";
import EGY from "assets/country/EGY.svg";
import IQ from "assets/country/IQ.svg";
import SA from "assets/country/SA.svg";
import US from "assets/country/US.svg";
import ER from "assets/country/ER.svg";
import EG from "assets/country/EG.svg";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart } from "chart.js";

Chart.register(ChartDataLabels);
const images = [
    {label: "AE",value:AE},
    {label: "EGY",value:EGY},
    {label: "IQ",value:IQ},
    {label: "SA",value:SA},
    {label: "US",value:US},
    {label: "ER",value:ER},
    {label: "EG",value:EG}];

const TopDestinationsChart = ({ type, labels,data, backgroundColor }) => {
    let maxdataLength = Math.max.apply(Math, data.map(function(o) { return o.count; })) + 50;
    function drawImages(chart) {
        const ctx = chart.canvas.getContext('2d');
        var yAxis = chart.scales["y"];
        yAxis.ticks.forEach((value, index) => {
            const imageData = images.filter(data => data.label ===  value.label);
            var y = yAxis.getPixelForTick(index);
            var image = new Image();
            image.src = imageData.length> 0?imageData[0].value: '';
            ctx.drawImage(image, 0 , y-10, 30, 20);
        });
    }
    const plugins = [{
        beforeDraw: function(chart) {
            drawImages(chart);
        }
    }];
    const options = {
        inGraphDataShow: true,
        layout: {
            padding: {
                left: 40,
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                anchor: "end",
                color: "#374151",
                align: "right",
                offset: 8,
                font: {
                    weight: "bold",
                },
                formatter: Math.round,
            },
        },
        indexAxis: type,
        maintainAspectRatio: false,
        scales: {
            y: {
                grid: {
                    display: false,
                },
                ticks: {
                    align: "center",
                    crossAlign: "far",
                    color: "#2D3748",
                    font: {
                        size: 14,
                        weight: "400",
                    },
                },
            },
            x: {
                max: maxdataLength,
                ticks: {
                    color: "#9CA3AF",
                },
                grid: {
                    borderDash: [2, 5],
                },
            },
        },
    };
    const dataHorBar = {
        labels: labels,
        datasets: [
            {
                backgroundColor: backgroundColor,
                borderWidth: 1,
                ...(!!data.length && {
                    data: data?.map((val) => {
                        return val.count;
                    }),
                }),
            },
        ],
    };
    return (
        <React.Fragment>
            <Bar options={options} data={dataHorBar} width={100} height={50} plugins={plugins} />
        </React.Fragment>
    );
};

export default memo(TopDestinationsChart);
