import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useTranslation } from "react-i18next";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Col, Row } from "react-bootstrap";
import filterFactory from "react-bootstrap-table2-filter";
import { SearchBar, SelectFilterColums } from "components/Tables/Columns";
import { textFilter } from "react-bootstrap-table2-filter";
import moment from "moment";
import { ChevronUp, ChevronDown } from "react-bootstrap-icons";
import pageListRenderer from "../Tables/PageListRenderer";
import sizePerPageOptionRenderer from "../Tables/SizePerPageOptionRenderer";
import CustomTotal from "../Tables/CustomTotal";
import ChargeableWeight from "../Tables/ChargeableWeight";
import GetVolumeWeight from "../Tables/GetVolumeWeight";
import { useParams } from "react-router-dom";
import { logoUrl } from "config/config";
import { GLOBAL_CONSTANTS } from "constants/constants";

let countryTypeFilter, customerFilter, suitsFilter, statusFilter;

function ParcelsTable(props) {
    const { t } = useTranslation();
    let countryTypeFilter, customerFilter, suitsFilter, statusFilter;
    let { type } = useParams();
    const onFilter = (value, id, subId) => {
        if (!value) value = "";
        if (id === "user") {
            if (subId === "country") countryTypeFilter(value);
            else customerFilter(value);
        } else if (id === "ParcelSuite") suitsFilter(value);
        else if (id === "ParcelHasParcelStatus") statusFilter(value);
    };

    const changeTimeZone = (date, timeZone) => {
        if (typeof date === 'string') {
            return new Date(
                new Date(date).toLocaleString('en-US', {
                    timeZone,
                }),
            );
        }

        return new Date(
            date.toLocaleString('en-US', {
                timeZone,
            }),
        );
    }

    function parcelCreatedDate(cell, showTime = false, country = null) {
        let convertedDate = cell
        if (showTime && country) {
            let timeZone = country?.toLocaleUpperCase() === "CN"
                ? "Asia/Shanghai"
                : country?.toLocaleUpperCase() === "US"
                    ? "America/New_York"
                    : country?.toLocaleUpperCase() === "GB"
                        ? "Europe/London"
                        : country?.toLocaleUpperCase() === "TR"
                            ? "Asia/Istanbul"
                            : "Asia/Dubai"
            convertedDate = changeTimeZone(cell, timeZone)
        }
        if (convertedDate) return moment(convertedDate).format(showTime ? "DD MMM YYYY, h:mm a" : "DD MMM YYYY");
    }

    const parcelsColumns = [
        {
            dataField: "parcel_start_status.created_at",
            text: t('Date'),
            sort: true,
            formatter: (cell, row) => {
                let warehouseCountry = row?.uuid?.slice(2,4)
                const dateAndTime = parcelCreatedDate(cell, true, warehouseCountry)?.split(",") || []
                const date = dateAndTime[0].trim()
                const time = dateAndTime[1].trim()
                return <>
                    <span className="d-block">{date}</span>
                    <small className="text-muted">{time}</small>
                </>
            },
            csvFormatter: (cell) => {
                return parcelCreatedDate(cell, true);
            },
        },
        {
            dataField: "uuid",
            text: t("Parcel ID"),
            classes: "highlighted-text",
        },
        {
            text: t("Items"),
            dataField: "ParcelHasItems",
            formatter: (cell) => {
                let quantity = 0
                cell.forEach(parcelItem => {
                    quantity += parcelItem.quantity
                });
                return quantity
            },
            csvFormatter: (cell) => {
                let quantity = 0
                cell.forEach(parcelItem => {
                    quantity += parcelItem.quantity
                });
                return quantity
            },
        },
        {
            text: t("Destination"),
            filter: textFilter({
                getFilter: (filter) => {
                    countryTypeFilter = filter;
                },
            }),
            dataField: "ParcelAddress.country",
        },
        {
            text: t("Customer"),
            dataField: "user",
            formatter: (cell, row) => {
                return cell ? <div>
                    <span className="d-flex">{row.user?.user_pin}</span>
                    <span className="font-12">{row.user?.name}</span></div> : '';
            },
            csvFormatter: (cell, row) => {
                return cell ? row.user?.user_pin + " ," + row.user?.name : '';
            },
        },
        {
            text: t('Customer_Pin'),
            dataField: "user.user_pin",
            hidden: true,
            csvExport: false
        },
        {
            text: t('Customer_Name'),
            dataField: "user.name",
            hidden: true,
            csvExport: false
        },
        {
            text: t("Suite"),
            dataField: "ParcelSuite.suite.suite",
            classes: "highlighted-text",
            formatter: (cell) => {
                return cell ? cell : '-';
            },
            csvFormatter: (cell, row) => {
                return cell ? cell : '-';
            },

        },
        {
            dataField: 'weight_unit',
            text: t('A_Wt'),
            formatter: (cell, row) => {
                return row.weight_value ? <div>
                    <strong>{row.weight_value}</strong> {cell}
                </div> : <div>
                    <strong>{0}</strong>
                </div>
            },
            csvFormatter: (cell, row) => {
                return row.weight_value ? row.weight_value + " " + cell : "";
            },
        },
        {
            dataField: 'dimension_height',
            text: t('V_Wt'),
            formatter: (cell, row) => {
                let volumeWeight = GetVolumeWeight(row);

                return volumeWeight ? <div>
                    <strong>{volumeWeight?.toFixed(2)}</strong> {row.weight_unit}
                </div> : <div>
                    <strong>{0}</strong>
                </div>
            },
            csvFormatter: (cell, row) => {
                let volumeWeight = GetVolumeWeight(row);

                return volumeWeight ? volumeWeight?.toFixed(2) + " " + row.weight_unit : '';
            },
        },
        {
            dataField: "weight_value",
            text: t("Ch_Wt"),
            sort: true,
            formatter: (cell, row) => {
                const actualWeight = row.weight_value;
                const VolumeWeight = GetVolumeWeight(row);
                const chargeableWeight = ChargeableWeight(VolumeWeight, actualWeight);
                return (
                    chargeableWeight ?
                        <div>
                            <strong>{chargeableWeight?.toFixed(2)}</strong> {row.weight_unit}
                        </div>
                        :
                        <div>
                            <strong>{0}</strong>
                        </div>);
            },
            csvFormatter: (cell, row) => {
                const actualWeight = row.weight_value;
                const VolumeWeight = GetVolumeWeight(row);
                const chargeableWeight = ChargeableWeight(VolumeWeight, actualWeight);
                return chargeableWeight?.toFixed(2) + " " + row.weight_unit;
            },
        },
        {
            dataField: "amount",
            text: t("Value"),
            sort: true,
            formatter: (cell, row) => {
                return cell ? (
                    <div>
                        <strong>{cell}</strong> {row.currency}
                    </div>
                ) : '';
            },
            csvFormatter: (cell, row) => {
                return cell ? cell + " " + row.currency : '';
            },
        },
        {
            text: t("Status"),
            filter: textFilter({
                getFilter: (filter) => {
                    statusFilter = filter;
                },
                defaultValue: '',
            }),
            sort: true,
            dataField: "ParcelHasParcelStatus.ParcelStatus.name",
            formatter: (cell, row) => {
                return (cell)
                    ? <div className={cell.toLowerCase().replace(/[^a-zA-Z ]/g, " ").split(' ').join('-')}>{cell}</div>
                    : ""

            },
        },
        {
            dataField: "ShippingCompany.logo",
            text: t("Shipping"),
            style: {
                "width": 100
            },
            formatter: (cell, row) => {
                if (cell && (row?.ParcelPayment?.service_identifier === "")) {
                    return (
                        <img width="100" height="80" src={logoUrl + cell} alt="shipping_company" />
                    );
                } else if (cell && (row?.ParcelPayment?.service_identifier !== "")) {
                    return (
                        <div>
                            <img width="100" height="80" src={logoUrl + cell} alt="shipping_company" />
                            <span style={{ "font-size": "7px" }}><strong>{t(row?.ParcelPayment?.service_identifier ? row?.ParcelPayment?.service_identifier.replace(/_/g, " ") : '')}</strong></span>
                        </div>
                    );
                }
                if (cell) return <img width="120" height="80" src={logoUrl + cell} alt="shipping_company" />;
            },
            csvFormatter: (cell, row) => {
                return row?.ShippingCompany?.shipping_name && row?.ParcelPayment?.service_identifier ? t(row?.ShippingCompany?.shipping_name) + " - " + t(row?.ParcelPayment?.service_identifier.replace(/_/g, " ")) : row?.ShippingCompany?.shipping_name;
            }
        },
        // export only columns
        {
            dataField: 'awb',
            text: t('AWB'),
            hidden: true
        },
        {
            dataField: 'ParcelShipmentTracking.tracking_number',
            text: t('Tracking'),
            hidden: true,
            csvFormatter: (cell, row) => {
                return cell ? cell : row?.parent_shipment !== null ? row?.parent_shipment?.tracking_number:"-";
            },
        },
        {
            dataField: 'parent_shipment.awb',
            text: t('Parent Awb'),
            hidden: true,
            csvFormatter: (cell, row) => {
                return cell ? cell : '-';
            },
        },
        {
            dataField: 'Eteller.name',
            text: t('Supplier'),
            hidden: true,
            csvFormatter: (cell, row) => {
                return cell ? cell : '-';
            },
        },

        {
            dataField: 'dimension_length',
            text: t('Dimensions'),
            hidden: true,
            csvFormatter: (cell, row) => {
                const w = row.dimension_width;
                const h = row.dimension_height;
                return cell ? cell + " x " + w + " x " + h + "  " + row.dimensions_unit : '-';
            },
        },

        {
            dataField: 'created_at',
            text: t("Created Day"),
            hidden: true,
            csvFormatter: (cell, row) => {
                return cell ? moment(cell).utc().format("DD MMM yyyy hh:mm A") : ""
            },
        },
        {
            dataField: 'updated_at',
            text: t("Last Update"),
            hidden: true,
            csvFormatter: (cell, row) => {
                return cell ? moment(cell).utc().format("DD MMM yyyy hh:mm A") : ""
            },
        },

    ];

    const expandRow = {
        renderer: (row) => {
            const actualWeight = row.weight_value
            const l = row.dimension_length;
            const w = row.dimension_width;
            const h = row.dimension_height;

            let volumeWeight = GetVolumeWeight(row);
            let description = [];
            if (row.ParcelHasItems)
                row.ParcelHasItems.map(function (e1) {
                    description.push(e1.commodity)
                })

            return (
                <Row className="expanding-row px-4 py-4">
                    <Col xs={12} sm={12} md={12} lg={5}>
                        <Row className="expand-data">
                            <Col xs={12} sm={12} md={12} lg={5} className="label">
                                {t("AWB")}
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} className="value">
                                {row.awb}
                            </Col>
                        </Row>
                        <Row className="expand-data">
                            <Col xs={12} sm={12} md={12} lg={5} className="label">
                                {t("Tracking")}
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} className="value">
                                {row.ParcelShipmentTracking?.tracking_number ? row.ParcelShipmentTracking.tracking_number : '-'}
                            </Col>
                        </Row>
                        <Row className="expand-data">
                            <Col xs={12} sm={12} md={12} lg={5} className="label">
                                {t("Supplier")}
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} className="value">
                                {row.Eteller?.name ? row.Eteller.name : '-'}
                            </Col>
                        </Row>
                        <Row className="expand-data">
                            <Col xs={12} sm={12} md={12} lg={5} className="label">
                                {t("Created Day")}
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} className="value">
                                {moment(row.created_at).format("DD MMM yyyy hh:mm A")}
                            </Col>
                        </Row>
                        <Row className="expand-data">
                            <Col xs={12} sm={12} md={12} lg={5} className="label">
                                {t("Description")}
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} className="value">
                                {
                                    description.join(",")
                                }
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={5}>
                        <Row className="expand-data">
                            <Col xs={12} sm={12} md={12} lg={5} className="label">
                                {t("Actual_Weight")}
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} className="value">
                                <strong>{actualWeight ? actualWeight : ''} </strong>
                                {row.weight_unit ? row.weight_unit : '-'}
                            </Col>
                        </Row>
                        <Row className="expand-data">
                            <Col xs={12} sm={12} md={12} lg={5} className="label">
                                {t("Dimensions")}
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} className="value">
                                {l} {l && <i className="bx bx-x"></i>}
                                {w} {w && <i className="bx bx-x"></i>}
                                {h} {l && w && h ? row.dimensions_unit : '-'}
                            </Col>
                        </Row>
                        <Row className="expand-data">
                            <Col xs={12} sm={12} md={12} lg={5} className="label">
                                {t("Volume_Weight")}
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} className="value">
                                <strong>{volumeWeight?.toFixed(2)} </strong>
                                {row.weight_unit ? row.weight_unit : ''}
                            </Col>
                        </Row>
                        <Row className="expand-data">
                            <Col xs={12} sm={12} md={12} lg={5} className="label">
                                {t("Last Update")}
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} className="value">
                                {moment(row.updated_at).format("DD MMM yyyy hh:mm A")}
                            </Col>
                        </Row>
                        {row?.ParcelFiles && row?.ParcelFiles.length > 0 &&
                            <Row className="expand-data">
                                <Col xs={12} sm={12} md={12} lg={5} className="label">
                                    {t("Files")}
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={7} className="value">
                                    {
                                        row.ParcelFiles?.map((files, index) => {
                                            return (
                                                <a href={files.file} target={"_blank"} style={{ "padding-right": "10px" }}>
                                                    File {index + 1}
                                                </a>
                                            )
                                        })
                                    }
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>
            )
        },
        showExpandColumn: true,
        expandByColumnOnly: true,
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            return "";
        },
        expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
                return <ChevronUp color="#2D3748" size={10} className="mx-2 my-3 arrow-icon" />;
            }
            return <ChevronDown color="#2D3748" size={10} className="mx-2 my-3 arrow-icon" />;
        },
    };

    const options = {
        sizePerPage: GLOBAL_CONSTANTS.SIZE_PER_PAGE,
        hideSizePerPage: false,
        hidePageListOnlyOnePage: true,
        sizePerPageOptionRenderer,
        showTotal: true,
        pageListRenderer,
        paginationTotalRenderer: CustomTotal,

    };

    const { ExportCSVButton } = CSVExport;
    return (
        <div className="table-responsive px-4">
            {parcelsColumns && (
                <ToolkitProvider
                    keyField="uuid"
                    data={props.parcelsData}
                    columns={parcelsColumns}
                    search={{ searchFormatted: true }}
                    exportCSV={{
                        fileName: "ParcelTable.csv",
                        noAutoBOM: false,
                        exportAll: false,
                        onlyExportFiltered: true,
                    }}
                >
                    {(props) => {
                        return (
                            <div className="table-container my-4">
                                <Row className="search dispatch-search mx-2">
                                    <Col xs={12} sm={12} md={12} lg={9}>
                                        <Row>
                                            <Col xs={12} sm={6} md={6} lg={3} className="px-3">
                                                <SelectFilterColums
                                                    preFilteredRows={props.baseProps.data}
                                                    id="user"
                                                    subId="country"
                                                    text={t("Destination")}
                                                    {...props.searchProps}
                                                    onFilterClick={onFilter}
                                                />
                                            </Col>
                                            {/*<Col xs={12} sm={6} md={6} lg={3}>
                                            <SelectFilterColums
                                                preFilteredRows={props.baseProps.data}
                                                id="user"
                                                subId="name"
                                                text={t("Customer")}
                                                {...props.searchProps}
                                                onFilterClick={onFilter}
                                            />
                                        </Col>*/}
                                            {/*<Col xs={12} sm={6} md={6} lg={3}>
                                            <SelectFilterColums
                                                preFilteredRows={props.baseProps.data}
                                                id="ParcelSuite"
                                                subId="suite"
                                                otherSubId="suite"
                                                text={t("Suite")}
                                                {...props.searchProps}
                                                onFilterClick={onFilter}
                                            />
                                        </Col>*/}
                                            <Col xs={12} sm={6} md={6} lg={3}>
                                                <SelectFilterColums
                                                    preFilteredRows={props.baseProps.data}
                                                    id="ParcelHasParcelStatus"
                                                    subId="ParcelStatus"
                                                    otherSubId="name"
                                                    text={t("Status")}
                                                    {...props.searchProps}
                                                    onFilterClick={onFilter}
                                                    selectedVal={type}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={3} className="px-3">
                                        <SearchBar {...props.searchProps} />
                                    </Col>
                                    <Col className="d-none">
                                        <ExportCSVButton {...props.csvProps} id="parcelExportButton">
                                            Export
                                        </ExportCSVButton>
                                    </Col>
                                </Row>
                                <Row className="table-data my-3">
                                    <Col>
                                        <BootstrapTable
                                            expandRow={expandRow}
                                            {...props.baseProps}
                                            wrapperClasses="table-wrapper my-3 "
                                            headerClasses="header-class"
                                            bordered={false}
                                            noDataIndication={t("No_Record")}
                                            filter={filterFactory()}
                                            rowClasses="custom-table-row"
                                            condensed={true}
                                            pagination={paginationFactory(options)}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        )
                    }}
                </ToolkitProvider>
            )}
        </div>
    );
}

export default ParcelsTable;
