import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import { useTranslation } from "react-i18next";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Col, Row  } from "react-bootstrap";
import filterFactory from 'react-bootstrap-table2-filter';
import { SearchBar, SelectFilterColums } from "components/Tables/Columns";
import { textFilter } from 'react-bootstrap-table2-filter';
import moment from "moment";
import { ChevronUp, ChevronDown } from 'react-bootstrap-icons';
import pageListRenderer from '../Tables/PageListRenderer';
import sizePerPageOptionRenderer from '../Tables/SizePerPageOptionRenderer';
import CustomTotal from "../Tables/CustomTotal";
import { GLOBAL_CONSTANTS } from "constants/constants";

let promoTypeFilter, userCountryFilter;

function PromoCodeTable(props) {
  const { t } = useTranslation();
  const onFilter = (value, id) => {
    if(!value)
      value = '';
    if(id === 'coupon_type')
      promoTypeFilter(value);
    else if(id === 'country_code')
      userCountryFilter(value);
  };

 function totalCost(row) {
  let totalCostVal = 0;
  row.Transactions.length > 0 && row.Transactions.forEach(Transaction => {
    if(Transaction.promo_code_value)
      totalCostVal += parseFloat(Transaction.promo_code_value)
  });
  return totalCostVal?.toFixed(2);
 }

 const columns = [
  {
    dataField: "id",
    text: t('NO'),
  },
  {
    dataField: "start_date",
    text: t('Start_Date'),
    sort: true,
    formatter: (cell) => {
      if(cell)
        return moment(cell).utc().format("DD MMM yyyy");
    },
    csvFormatter: (cell, row) => {
      if(cell)
        return moment(cell).utc().format("DD MMM yyyy");
    },
  },
  {
    dataField: "expiry_date",
    text: t('Expiry_Date'),
    sort: true,
    formatter: (cell) => {
      if(cell)
        return moment(cell).utc().format("DD MMM yyyy");
    },
    csvFormatter: (cell, row) => {
      if(cell)
        return moment(cell).utc().format("DD MMM yyyy");
    },
  },
  {
    text: t('Promo_Name'),
    dataField: "name",
    formatter: (cell) => {
      return  <div style={{ color: "#1E88E5" }}>{cell}</div>
    }
  },
  {
    text: t('Value'),
    dataField: "discount",
    formatter: (cell, row) => {
      return  row.discount_type === "flat"?
      <div><b>{cell}</b>{t("$")}</div>: <div><b>{cell}</b>{t("%")}</div>
    },
    csvFormatter: (cell, row) => {
        return row.discount_type === "flat"
        ?cell+t("$"):cell+t("%");
    },
  },
  {
    text: t('Type'),
    dataField: "coupon_type",
    formatter: (cell) => {
      return  cell.charAt(0).toUpperCase() + cell.slice(1)
    },
    filter: textFilter({
      getFilter: (filter) => {
        promoTypeFilter = filter;
      }
    }),
    csvFormatter: (cell, row) => {
      return  cell.charAt(0).toUpperCase() + cell.slice(1)
    }
  },
  {
    text: t('Discount'),
    dataField: "discount_type",
    formatter: (cell) => {
      return cell === "percent" ? (
        <span className="percent_discount">
          {t('Percentage')}
        </span>
      ) : (
        <span className="flat_discount">
          {t('Flat_Discount')}
        </span>
      );
    },
    csvFormatter: (cell, row) => {
      return cell === "percent" 
      ? t('Percentage')
      : t('Flat_Discount')
    }
  },
  {
    text: t('Country'),
    dataField: "country_code",
    filter: textFilter({
      getFilter: (filter) => {
        userCountryFilter = filter;
      }
    }),
    formatter: (cell, row) => {
      return cell?cell: 'All'
    },
    csvFormatter: (cell, row) => {
      return cell?cell: 'All'
    }
  },
  {
    text: t('Used'),
    dataField: "Transactions.length",
  },
  {
    text: t('Coupon For'),
    dataField: "CouponUsers",
    formatter: (cell, row) => {
      return cell && cell?.length > 0 ? 
        cell.map(e => e.user.user_pin).join(" , ")
      : t("Group")
    },
    csvFormatter: (cell, row) => {
      return cell && cell?.length > 0 ? 
        cell.map(e => e.user.user_pin).join(" , ")
      : t("Group")
    }
  },
  {
    text: t('Total_Cost'),
    dataField: "total",
    formatter: (cell, row) => {
      return totalCost(row)
    },
    csvFormatter: (cell, row) => {
      return totalCost(row)
    }
  },
  {
    text: t('Status'),
    dataField: "active",
    sort: true,
    formatter: (cell) => {
      return cell === 1 ? (
        <span className="active_status">
          {t('Active')}
        </span>
      ) : (
        <span className="inactive_status">
          {t('Inactive')}
        </span>
      );
    }
  },
  {
    text: t('Promo_Code'),
    dataField: "code",
    hidden: true
  },
  {
    text: t('User_Type'),
    dataField: "is_open",
    hidden: true,
    csvFormatter: (cell, row) => {
      return (cell === 1)?t('All'):t('Single_Multiple')
    }
  },
  
];

const expandRow = {
  renderer: row => {
    return(
    <Row className='expanding-row px-4 py-4'>
      <Col xs={12} sm={12} md={12} lg={5}>
        <Row className="expand-data">
          <Col xs={12} sm={12} md={12} lg={5} className="label">{t('Promo_Code')}</Col>
          <Col xs={12} sm={12} md={12} lg={7} className="value">{row.code}</Col>
        </Row>
        <Row className="expand-data">
          <Col xs={12} sm={12} md={12} lg={5} className="label">{t('Discount_Type')}</Col>
          <Col xs={12} sm={12} md={12} lg={7} className="value">{row.discount_type === "percent"?t("Percentage"):t("Flat_Discount") }</Col>
        </Row>
        <Row className="expand-data">
          <Col xs={12} sm={12} md={12} lg={5} className="label">{t('Start_Date')}</Col>
          <Col xs={12} sm={12} md={12} lg={7} className="value">{moment(row.start_date).format("DD MMM yyyy")}</Col>
        </Row>
      </Col>
      <Col xs={12} sm={12} md={12} lg={7}>
        <Row className="expand-data">
          <Col xs={12} sm={12} md={12} lg={3} className="label">{t('Country')}</Col>
          <Col xs={12} sm={12} md={12} lg={9} className="value">{row.country_code?row.country_code: 'All'}</Col>
        </Row>
        <Row className="expand-data">
          <Col xs={12} sm={12} md={12} lg={3} className="label">{t('User_Type')}</Col>
          <Col xs={12} sm={12} md={12} lg={9} className="value">{(row.is_open === 1)?t('All'):t('Single_Multiple')}</Col>
        </Row>
        <Row className="expand-data">
          <Col xs={12} sm={12} md={12} lg={3} className="label">{t('Expiry_Date')}</Col>
          <Col xs={12} sm={12} md={12} lg={9} className="value">{moment(row.expiry_date).format("DD MMM yyyy")}</Col>
        </Row>
      </Col>
    </Row>
  )},
  showExpandColumn: true,
  expandByColumnOnly: true,
  expandHeaderColumnRenderer: ({ isAnyExpands }) => {
    return '';
  },
  expandColumnRenderer: ({ expanded }) => {
    if (expanded) {
      return (
        <ChevronUp color="#2D3748" size={10} className="mx-2 my-3 arrow-icon" />
      );
    }
    return (
      <ChevronDown color="#2D3748" size={10} className="mx-2 my-3 arrow-icon" />
    );
  }
};

  const options = {
    sizePerPage: GLOBAL_CONSTANTS.SIZE_PER_PAGE,
    hideSizePerPage: false,
    hidePageListOnlyOnePage: true,
    sizePerPageOptionRenderer,
    showTotal: true,
    pageListRenderer,
    paginationTotalRenderer: CustomTotal,
  };
  
  const { ExportCSVButton } = CSVExport;

  return (
    <div className="table-responsive">
      {columns &&
        <ToolkitProvider
          keyField="id"
          data={ props.promoCodesData }
          columns={ columns }
          search={ { searchFormatted: true } }
          exportCSV={{
                        fileName: "PromoCodeTable.csv",
                        noAutoBOM: false,
                        exportAll: false,
                        onlyExportFiltered: true,
          }}
        >
          {
            props => (
              <div className="table-container my-4">
                <Row className="search mx-2 promo-search">
                  <Col xs={12} sm={6} md={6} lg={2} className="pl-4">
                    <SelectFilterColums preFilteredRows={props.baseProps.data} id="coupon_type" text={t("Promo_Type")} { ...props.searchProps } onFilterClick={onFilter} />
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={2}>
                    <SelectFilterColums preFilteredRows={props.baseProps.data} id="country_code" text={t("Country")} { ...props.searchProps }  onFilterClick={onFilter} />
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={8} className="px-4">
                    <SearchBar { ...props.searchProps } />
                  </Col>
                  <Col className="d-none">
                    <ExportCSVButton {...props.csvProps} id="promoExportButton">
                        Export
                    </ExportCSVButton>
                  </Col>
                </Row>
                <Row className="table-data my-3">
                  <Col>
                    <BootstrapTable
                      expandRow={ expandRow }
                      { ...props.baseProps }
                      wrapperClasses="table-wrapper my-3"
                      headerClasses="header-class"
                      bordered={ false }
                      noDataIndication={t("No_Record")}
                      filter={ filterFactory() }
                      rowClasses="custom-table-row"
                      condensed={true}
                      pagination={ paginationFactory(options) }
                    />
                  </Col>
                </Row>
              </div>
            )
          }
        </ToolkitProvider>
      }
    </div>
  );
}

export default PromoCodeTable;
