import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useTranslation } from "react-i18next";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Col, Row } from "react-bootstrap";
import filterFactory from "react-bootstrap-table2-filter";
import { SearchBar, SelectFilterColums } from "components/Tables/Columns";
import { textFilter } from "react-bootstrap-table2-filter";
import pageListRenderer from "../Tables/PageListRenderer";
import sizePerPageOptionRenderer from "../Tables/SizePerPageOptionRenderer";
import { ChevronUp, ChevronDown } from "react-bootstrap-icons";
import { logoUrl } from "config/config";
import moment from "moment";
import CustomTotal from "../Tables/CustomTotal";
import { GLOBAL_CONSTANTS } from "constants/constants";

let suiteFilter;

function DropTable(props) {
    const { t } = useTranslation();

    const onFilter = (value, id, subId) => {
        if (!value) value = "";
        if (id === "suite") suiteFilter(value);
    };

    const parcelsColumns = [
        {
            dataField: "created_at",
            text: t('Date'),
            sort:true,
            style: {
                "width": 200
            },
            formatter: (cell, row) => {
              if(cell)
                return moment(cell).utc().format("DD MMM yyyy");
            },
          },
        {
            dataField: "suite",
            text: t("Suite_Id"),
            classes: "highlighted-text",
            filter: textFilter({
                getFilter: (filter) => {
                    suiteFilter = filter;
                },
            }),
            style: {
                "width": 250
            },
        },
        {
            dataField: "ParcelSuites.length",
            text: t("Parcels to Drop"),
            sort: true,
        },
    ];

    const expandRow = {
        renderer: (row) => (
            <Row className="expanding-row pick">
                <Col xs={12} sm={12} md={12} lg={12}>
                    {row.ParcelSuites && row.ParcelSuites.length > 0 && <Row className="expand-data px-2 ">
                    <Col xs={5} sm={5} md={4} lg={4} className="value expand-val">
                    <span className="pr-3 pl-2"><strong>{t('No')}</strong></span><strong>{t('Parcel_Uuid')}</strong></Col>
                    <Col xs={2} sm={2} md={1} lg={1} className="value suites-expand"><strong>{t('Items')}</strong></Col>
                    <Col xs={2} sm={2} md={7} lg={7} className="value"><strong>{t('Shipping_Company_Logo')}</strong></Col>
                    </Row>}
                    {row.ParcelSuites?.map((ParcelSuite, index) => {
                        let quantity = 0
                        ParcelSuite.parcel.ParcelHasItems.forEach(parcelItem => {
                            quantity += parcelItem.quantity
                        });
                        return (
                        <Row className="expand-data px-2" key={index}>
                            <Col xs={5} sm={5} md={4} lg={4} className="value expand-val ">
                            <span className="pr-4 pl-2">{index+1}</span>
                            <span className=" highlighted-text">{ParcelSuite?.parcel.uuid}</span>
                            </Col>
                            <Col xs={2} sm={2} md={1} lg={1} className="value">
                                {quantity}
                            </Col>
                            <Col xs={2} sm={2} md={7} lg={7} className="value">
                            <img width="100" height="80" src={logoUrl + ParcelSuite?.parcel.ShippingCompany.logo} alt={ParcelSuite?.parcel.ShippingCompany.shipping_name} />
                            </Col>
                        </Row>
                    )}
                )}
                </Col>
            </Row>
        ),
        showExpandColumn: true,
        expandByColumnOnly: true,
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            return "";
        },
        expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
                return <ChevronUp color="#2D3748" size={10} className="mx-2 my-3 arrow-icon" />;
            }
            return <ChevronDown color="#2D3748" size={10} className="mx-2 my-3 arrow-icon" />;
        },
    };

    const options = {
        sizePerPage: GLOBAL_CONSTANTS.SIZE_PER_PAGE,
        hideSizePerPage: false,
        hidePageListOnlyOnePage: true,
        sizePerPageOptionRenderer,
        showTotal: true,
        pageListRenderer,
        paginationTotalRenderer: CustomTotal,
    };

    const { ExportCSVButton } = CSVExport;

    return (
        <div className="table-responsive">
            {parcelsColumns && (
                <ToolkitProvider
                    keyField="suite"
                    data={props.dropData}
                    columns={parcelsColumns}
                    search={{ searchFormatted: true }}
                    exportCSV={{
                        fileName: "DropTable.csv",
                        noAutoBOM: false,
                        exportAll: false,
                        onlyExportFiltered: true,
                    }}
                >
                    {(props) => (
                        <div className="table-container my-4">
                            <Row className="search mx-2">
                                <Col xs={12} sm={12} md={12} lg={8} className="d-flex px-4">
                                    <SelectFilterColums
                                        preFilteredRows={props.baseProps.data}
                                        id="suite"
                                        text={t("Suite_Id")}
                                        {...props.searchProps}
                                        onFilterClick={onFilter}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={4}  className="px-4">
                                    <SearchBar {...props.searchProps} />
                                </Col>
                                <Col className="d-none">
                                    <ExportCSVButton {...props.csvProps} id="dropExportButton">
                                        Export
                                    </ExportCSVButton>
                                </Col>
                            </Row>
                            <Row className="table-data my-3">
                                <Col>
                                    <BootstrapTable
                                        expandRow={expandRow}
                                        {...props.baseProps}
                                        wrapperClasses="table-wrapper my-3"
                                        headerClasses="header-class"
                                        bordered={false}
                                        noDataIndication={t("No_Record")}
                                        filter={filterFactory()}
                                        rowClasses="custom-table-row"
                                        condensed={true}
                                        pagination={paginationFactory(options)}
                                        sort={ { dataField: 'created_at', order: 'desc' } }
                                    />
                                </Col>
                            </Row>
                        </div>
                    )}
                </ToolkitProvider>
            )}
        </div>
    );
}

export default DropTable;
