import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useTranslation } from "react-i18next";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Col, Row } from "react-bootstrap";
import filterFactory from "react-bootstrap-table2-filter";
import { SearchBar, SelectFilterColums } from "components/Tables/Columns";
import pageListRenderer from "../Tables/PageListRenderer";
import sizePerPageOptionRenderer from "../Tables/SizePerPageOptionRenderer";
import moment from "moment";
import { ChevronUp, ChevronDown } from "react-bootstrap-icons";
import CustomTotal from "../Tables/CustomTotal";
import { logoUrl } from "config/config";
import { textFilter } from 'react-bootstrap-table2-filter';
import ChargeableWeight from "../Tables/ChargeableWeight"
import GetVolumeWeight from "../Tables/GetVolumeWeight";
import ParcelItems from "../Tables/ParcelItems";
import { GLOBAL_CONSTANTS } from "constants/constants";

let statusFilter;

function ConsolidationTable(props) {
    const { t } = useTranslation();

    const onFilter = (value, id, subId) => {
        if (!value) value = "";
        //if (id === "suite") suiteFilter(value);
        if(id === 'parcel_current_status')
            statusFilter(value);
    };
    function parcelCreatedDate(cell) {
        if (cell) return moment(cell).utc().format("DD MMM yyyy");
    }

    const consolidationColumns = [
        {
            dataField: "parcel_current_status.created_at",
            text: t("DATE"),
            sort: true,
            style: {
                "width": 100
            },
            formatter: (cell, row) => {
               return parcelCreatedDate(cell);
            },
            csvFormatter: (cell) => {
                return parcelCreatedDate(cell);
            },
        },
        {
            dataField: "parcel_console_status.created_at",
            text: t("Consolidate Date"),
            sort: true,
            style: {
                "width": 100
            },
            formatter: (cell, row) => {
               return parcelCreatedDate(cell);
            },
            csvFormatter: (cell) => {
                return parcelCreatedDate(cell);
            },
        },
        {
            dataField: "uuid",
            text: t("Parcel ID"),
            classes: "highlighted-text",
        },
        {
            text: t("Parcels"),
            dataField: "parcel_children.length",
        },
        {
            text: t("Items"),
            dataField: "parcel_children",
            formatter: (cell) => {
                return ParcelItems(cell);
            },
            csvFormatter: (cell) => {
                return ParcelItems(cell);
            },
        },
        {
            text: t("Dest."),
            dataField: "ParcelAddress.country",
            csvFormatter: (cell, row) => {
                return cell?cell:"";
            },
        },
        {
            text: t('Customer'),
            dataField: "user.user_pin",
            formatter: (cell, row) => {
              return <div>
                <span className="d-flex">{cell}</span>
                <span className="font-12">{row.user?.name}</span></div>;
            },
            csvFormatter: (cell, row) => {
              return cell +" - "+ row.user?.name;
            },
          },
          {
            text: t('Customer_Name'),
            dataField: "user.name",
            hidden:true,
            csvExport:false
          },
          {
            text: t('Customer_Pin'),
            dataField: "user.user_pin",
            hidden:true,
            csvExport:false
          },
        {
            text: t("Suite"),
            dataField: "suite",
            classes: "highlighted-text",
        },
        {
            text: t("Tracking"),
            dataField: "ParcelShipmentTracking.tracking_number",
            csvFormatter: (cell, row) => {
                return cell?cell:"";
            },
        },
        {
            text: t("A_Wt"),
            dataField: "weight_value",
            formatter: (cell, row) => {
                return (
                    cell ?
                    <div>
                        <strong>{cell}</strong>
                    </div>
                :
                <div>
                    <strong>{0}</strong>
                </div>);
            },
        },
        {
            dataField: "dimension_height",
            text: t("Ch_Wt"),
            sort: true,
            formatter: (cell, row) => {
                const actualWeight = row.weight_value;
                const VolumeWeight = GetVolumeWeight(row);
                const chargeableWeight = ChargeableWeight(VolumeWeight, actualWeight);
                return (
                chargeableWeight ?
                    <div>
                        <strong>{chargeableWeight?.toFixed(2)}</strong>
                    </div>
                :
                <div>
                    <strong>{0}</strong>
                </div>);
            },
            csvFormatter: (cell, row) => {
                const actualWeight = row.weight_value;
                const VolumeWeight = GetVolumeWeight(row);
                const chargeableWeight = ChargeableWeight(VolumeWeight, actualWeight);
                return chargeableWeight?chargeableWeight?.toFixed(2):"";
            },
        },
        {
            dataField: 'dimension_length',
            text: t('Dims'),
            style: {
                "width": 100
            },
            formatter: (cell, row) => {
                const w = row.dimension_width;
                const h = row.dimension_height;
                
                return cell?cell+" x "+w+" x "+h:'-';
            },
            csvFormatter: (cell, row) => {
                return cell?cell+" x "+row.dimension_width+" x "+row.dimension_height+"  "+row.dimensions_unit:'-';
            },
        },
        {
            text: t("Status"),
            sort: true,
            dataField: "parcel_current_status.ParcelStatus.name",
            formatter: (cell, row) => {
                return (cell)
                ?<div className={"font-12 "+cell.toLowerCase().replace(/[^a-zA-Z ]/g, " ").split(' ').join('-')}>{t(cell)}</div>
                :""
                
            },
            filter: textFilter({
                getFilter: (filter) => {
                    statusFilter = filter;
                },
              }),
        },
        {
            dataField: "ShippingCompany.logo",
            text: t("Shipping"),
            style: {
                "width": 100
            },
            formatter: (cell, row) => {
                if(cell && (row?.ParcelPayment?.service_identifier === "")){
                    return (
                      <img width="100" height="80" src={logoUrl + cell} alt="shipping_company" />
                    ); 
                  } else if (cell && (row?.ParcelPayment?.service_identifier !== "")){
                    return (
                      <div>
                        <img width="100" height="80" src={logoUrl + cell} alt="shipping_company" />
                        <span style={{"font-size":"7px"}}><strong>{t(row?.ParcelPayment?.service_identifier?row?.ParcelPayment?.service_identifier.replace(/_/g, " "):'')}</strong></span>
                      </div>
                    ); 
                  }
            },
            csvFormatter: (cell, row) => {
                return row?.ShippingCompany?.shipping_name && row?.ParcelPayment?.service_identifier?t(row?.ShippingCompany?.shipping_name)+" - "+t(row?.ParcelPayment?.service_identifier.replace(/_/g, " ")): row?.ShippingCompany?.shipping_name;
            }
        },
        
    ];

    const expandRow = {
        renderer: (row) => {
            const columns = [
                {
                    dataField: "uuid",
                    text: t("Parcel ID"),
                    classes: "highlighted-text",
                },
                {
                    text: t("NO_OF_ITEMS"),
                    dataField: "ParcelHasItems",
                    formatter: (cell, row) => {
                        let quantity = 0
                        cell.forEach(parcelItem => {
                            quantity += parcelItem.quantity
                        });
                        return quantity
                    },
                },
                {
                    dataField: 'weight_unit',
                    text: t('Actual_Weight'),
                    formatter: (cell, row) => {
                        return (
                            row.weight_value ?
                            <div>
                                <strong>{row.weight_value}</strong> {cell}
                            </div>
                        :
                        <div>
                            <strong>{0}</strong>
                        </div>);
                    },
                },
                {
                    dataField: "weight_value",
                    text: t("Chargeable weight"),
                    formatter: (cell, row) => {
                        const actualWeight = row.weight_value;
                        const VolumeWeight = GetVolumeWeight(row);
                        const chargeableWeight = ChargeableWeight(VolumeWeight, actualWeight);
                        return (
                        chargeableWeight ?
                            <div>
                                <strong>{chargeableWeight?.toFixed(2)}</strong> {row.weight_unit}
                            </div>
                        :
                        <div>
                            <strong>{0}</strong>
                        </div>);
                    },
                    csvFormatter: (cell, row) => {
                        const actualWeight = row.weight_value;
                        const VolumeWeight = GetVolumeWeight(row);
                        const chargeableWeight = ChargeableWeight(VolumeWeight, actualWeight);
                        return chargeableWeight?.toFixed(2);
                    },
                },
                {
                    dataField: 'dimension_length',
                    text: t('Dimensions'),
                    formatter: (cell, row) => {
                        const w = row.dimension_width;
                        const h = row.dimension_height;
                        
                        return cell?cell+" x "+w+" x "+h+"  "+row.dimensions_unit:'-';
                    },
                }]
            return (
                <BootstrapTable
                    keyField="id"
                    data={row.parcel_children}
                    columns={columns}
                    wrapperClasses="inner-table my-3 mx-4 "
                    headerClasses="header-class"
                    bordered={false}
                    noDataIndication={t("No_Record")}
                    rowClasses="inner-table-row"
                    condensed={true} />
            )},
        showExpandColumn: true,
        expandByColumnOnly: true,
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            return "";
        },
        expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
                return <ChevronUp color="#2D3748" size={10} className="mx-2 my-3 arrow-icon" />;
            }
            return <ChevronDown color="#2D3748" size={10} className="mx-2 my-3 arrow-icon" />;
        },
    };

    const options = {
        sizePerPage: GLOBAL_CONSTANTS.SIZE_PER_PAGE,
        hideSizePerPage: false,
        hidePageListOnlyOnePage: true,
        sizePerPageOptionRenderer,
        showTotal: true,
        pageListRenderer,
        paginationTotalRenderer: CustomTotal,

    };

    const { ExportCSVButton } = CSVExport;

    return (
        <div className="table-responsive px-4">
            {consolidationColumns && (
                <ToolkitProvider
                    keyField="id"
                    data={props.consolidationData}
                    columns={consolidationColumns}
                    search={{ searchFormatted: true }}
                    exportCSV={{
                        fileName: "ConsolidationTable.csv",
                        noAutoBOM: false,
                        exportAll: false,
                        onlyExportFiltered: true,
                    }}
                >
                    {(props) => (
                        <div className="table-container my-4">
                            <Row className="search mx-2">
                                <Col xs={12} sm={12} md={12} lg={8} className="d-flex px-3">
                                    {/*<SelectFilterColums
                                        preFilteredRows={props.baseProps.data}
                                        id="suite"
                                        text={t("Suite_Id")}
                                        {...props.searchProps}
                                        onFilterClick={onFilter}
                                    />*/}
                                    <SelectFilterColums
                                        preFilteredRows={props.baseProps.data}
                                        id="parcel_current_status"
                                        subId="ParcelStatus"
                                        otherSubId="name"
                                        text={t("Status")}
                                        {...props.searchProps}
                                        onFilterClick={onFilter}
                                    />    
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={4} className="px-3">
                                    <SearchBar {...props.searchProps} />
                                </Col>
                                <Col className="d-none">
                                    <ExportCSVButton {...props.csvProps} id="consolidationExportButton">
                                        Export
                                    </ExportCSVButton>
                                </Col>
                            </Row>
                            <Row className="table-data my-3">
                                <Col>
                                    <BootstrapTable
                                        expandRow={expandRow}
                                        {...props.baseProps}
                                        wrapperClasses="table-wrapper my-3 mx-4 "
                                        headerClasses="header-class"
                                        bordered={false}
                                        filter={filterFactory()}
                                        noDataIndication={t("No_Record")}
                                        rowClasses="custom-table-row"
                                        condensed={true}
                                        pagination={paginationFactory(options)}
                                        sort={ { dataField: 'parcel_current_status.created_at', order: 'desc' }}
                                    />
                                </Col>
                            </Row>
                        </div>
                    )}
                </ToolkitProvider>
            )}
        </div>
    );
}

export default ConsolidationTable;
