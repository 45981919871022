import React from "react";

function ShipmentValue(cell, row) {
    let parcelData = [];
    let parcels = row.parcel_children;
      if(row.parcel_children.length <= 0)
        parcelData.push(row);

      
      if(parcels.length > 0) {
        parcelData.push(row);
        parcels.forEach(element => {
          if(element.uuid.match(/IC.*/) || element.uuid.match(/BC.*/)){
            parcelData.push(element)
          }
        });
      }
      let total_amount = 0;
      parcelData.forEach(parcelItems => {
        parcelItems.ParcelHasItems && parcelItems.ParcelHasItems.forEach(parcelItem => {
          total_amount += parcelItem.total_amount
        });
        parcelItems.parcel_children && parcelItems.parcel_children.forEach(parcelChildren => {
          parcelChildren.ParcelHasItems && parcelChildren.ParcelHasItems.forEach(parcelInnerItem => {
            total_amount += parcelInnerItem.total_amount
          });
        });
      });
    return total_amount;
};
export default ShipmentValue;
