import React, { memo } from "react";
import { Bar } from "react-chartjs-2";
import fedex from "assets/ShippingCompaniesIcons/fedex.svg";
import Aramex from "assets/ShippingCompaniesIcons/aramex.svg";
import DHL from "assets/ShippingCompaniesIcons/dhl.svg";
import UPS from "assets/ShippingCompaniesIcons/ups.svg";
import USPS from "assets/ShippingCompaniesIcons/usps.svg";

const images = [
    {label: "FedEx",value:fedex},
    {label: "Aramex",value:Aramex},
    {label: "DHL",value:DHL},
    {label: "UPS",value:UPS},
    {label: "USPS",value:USPS}];

const CourierShareChart = ({ type, data, backgroundColor, label }) => {
    function drawImages(chart) {
        const ctx = chart.canvas.getContext('2d');
        var xAxis = chart.scales["x"];
        var yAxis = chart.scales["y"];
        xAxis.ticks.forEach((value, index) => {
            const imageData = images.filter(data => data.label ===  value.label);
            var x = xAxis.getPixelForTick(index);
            var image = new Image();
            image.src = imageData.length> 0?imageData[0].value: '';
            ctx.drawImage(image, x - 50, yAxis.bottom + 12, 100, 40);
        });
    }
    const plugins = [{
        afterDraw: (chart) => {
            drawImages(chart)
        },
      }];
   
    const options = {
        responsive: true,
        layout: {
            padding: {
                bottom: 30,
            },
        },
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                anchor: "end",
                color: "black",
                align: "top",
                offset: 0,
                font: {
                    weight: "bold",
                },
                formatter: Math.round,
            }
        },

        indexAxis: type,
        maintainAspectRatio: false,
        scales: {
            y: {
                ticks: {
                    color: "#9CA3AF",
                    font: {
                        size: 12,
                        weight: "500",
                    },
                    padding: 10,
                },
                grid: {
                    borderDash: [2,5],
                },
            },
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: "#1A365D",
                    font: {
                        size: 12,
                        weight: "500",
                    }
                },
            },
        },
    };
    const dataHorBar = {
        labels: data?.map((name) => {
            return name["ShippingCompany.shipping_name"];
        }),
        datasets: [
            {
                barThickness: 76,
                backgroundColor: data?.map((name) => {
                    if(name["ShippingCompany.shipping_name"] === 'FedEx')
                        return "#452896";
                    else if(name["ShippingCompany.shipping_name"] === 'Aramex')
                        return "#ED3124";
                    else if(name["ShippingCompany.shipping_name"] === 'DHL')
                        return "#F0C103";
                    else if(name["ShippingCompany.shipping_name"] === 'USPS')
                        return "#005697";
                    else
                        return "#341B14";
                }),
                borderWidth: 1,
                ...(!!data.length && {
                    data: data?.map((val) => {
                        return val.count;
                    }),
                }),
            },
        ],
    };
    return (
        <React.Fragment>
            <Bar options={options} data={dataHorBar} width={100} height={50} plugins={plugins} />
        </React.Fragment>
    );
};

export default memo(CourierShareChart);
