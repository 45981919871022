require("dotenv").config();

/* eslint-disable max-len */

export const apiUrl = process.env.REACT_APP_WEBSITE_URL || "https://biapi.shopiniworld.com";
export const apiUrlWithoutHttps = process.env.REACT_APP_WEBSITE_WITHOUT_HTTPS || "http://biapi.shopiniworld.com";
export const logoUrl = "https://shipping.shopiniworld.com/img/front/logo/";
export const parcelHubLogin = "https://parcelhub.uat.shopiniworld.com/backend/login";
export const parcelHub = "https://parcelhub.uat.shopiniworld.com/";
export const appUrl = process.env.REACT_APP_WEBSITE_URL || "https://bi.shopiniworld.com";
