import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import Header from "./Layout/Header";
import ScaleLoader from "react-spinners/ScaleLoader";
import DropTable from "components/Drop/DropTable";
import * as config from "config/config";
import { API_CONSTANTS } from "constants/constants";
import DateRangePickerCustom from "components/Common/DateRangePicker/DateRangePickerCustom";

const Drop = () => {
    const [dropped, setDropped] = useState([]);
    const [loading, setLoading] = React.useState(true);

    //Date Picker Logic
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    var token = localStorage.getItem("accessToken");
    var userWarehouse = localStorage.getItem("userWarehouse");

    useEffect(() => {
        if(!token){
            window.location.reload(false);
        }
    }, [token]);

    useEffect(() => {
        const dateObj2 = {
            start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : "2020-04-15",
            end_date: endDate ? moment(endDate).format("YYYY-MM-DD") :moment().add(1,'days').format("YYYY-MM-DD"),
            warehouse_id: userWarehouse
        };
        const tokenConfig = {
            headers: { Authorization: `Bearer ${token}` },
        };
        async function fetchData() {
            const dropResponse = await axios.post(config.apiUrl + API_CONSTANTS.DROP, dateObj2, tokenConfig);
            setDropped(dropResponse.data.payload.droped);
            setLoading(false);
        }

        fetchData();
    }, [endDate]);
    return (
        <>
            {loading || !token ? (
                <div className="loader row justify-content-center">
                    <ScaleLoader color="#15397F" size={150} className=" mx-auto" />
                </div>
            ) : (
                <div className="drop">
                    <Header
                        title="Drop"
                        records={dropped.length}
                        data={dropped}
                        name="Drop"
                        buttonId="#dropExportButton"
                    >
                        <div className="mx-2">
                            <DateRangePickerCustom setStartDate={setStartDate} setEndDate={setEndDate}
                                startDate={startDate ? moment(startDate).format("YYYY-MM-DD") : "2020-04-15"}
                                endDate={endDate ? moment(endDate).format("YYYY-MM-DD") : moment().add(1,'days').format("YYYY-MM-DD")} />
                        </div>
                    </Header>
                    <div className="row d-flex px-2">
                        <DropTable dropData={dropped} />
                    </div>
                </div>
            )}
        </>
    );
};

export default Drop;
