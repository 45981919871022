import React from "react";
import { ExportFromTable } from "utils/Export/ExportFromTable";
import { useTranslation } from "react-i18next";

const Header = ({ title, children, records, data, name, buttonId, type }) => {
    const { t } = useTranslation();

    return (
        <div className="header_container d-flex flex-column pt-2 px-4">
            <p></p>
            <hr></hr>
            <div className="d-flex justify-content-between align-items-center mt-3 px-2 header">
                <div className="d-flex justify-content-start align-items-center heading_title">
                    <h1 className="table-header-title">{t(title)}</h1>
                    <div className="table-header-number-of-items ml-3 pb-1 align-self-end">
                        {records} {!type? t("records"): ""}
                    </div>
                </div>
                {!type &&
                <div className="d-flex justify-content-end">
                    {children}
                    <ExportFromTable buttonId={buttonId} csvData={data} fileName={name} />
                </div>
                }
            </div>
        </div>
    );
};

export default Header;
