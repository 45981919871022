import React from "react";
import { Search } from "react-bootstrap-icons";

export const SearchBar = (props) => {
    let input;
    const searchClick = () => {
        props.onSearch(input.value);
    };
    return (
        <div className="input-group float-right">
            <input
                className="form-control"
                ref={(n) => (input = n)}
                type="text"
                onKeyUp={searchClick}
                placeholder={"Search"}
            />
            <div className="input-group-btn px-2" onClick={searchClick}>
                <Search color="#2D3748" size={10} className="mx-2 my-2 arrow-icon" />
            </div>
        </div>
    );
};

export const SelectFilterColums = (props) => {
    // Calculate the options for filtering
    // using the preFilteredRows
    let selectedOption = ''
    if(props.selectedVal === 'pending')
        selectedOption = 'Payment Pending'
    let options = React.useMemo(() => {
        const options = new Set();

        props.preFilteredRows.forEach((row) => {
            if (!!row[props.id]) {
                if (row[props.id] && typeof row[props.id] !== "object") options.add(row[props.id]);
                else if (typeof row[props.id] === "object") {
                    if (typeof row[props.id][props.subId] === "object")
                        options.add(row[props.id][props.subId][props.otherSubId]);
                    else 
                        options.add(row[props.id][props.subId]);
                }
            }
        });
        return [...options.values()];
    }, [props.id, props.preFilteredRows]);

    if (props.id === "active" && props.table === "suite") {
        let newoptions = [];
        newoptions.push("Busy", "Empty");
        options = newoptions;
    }
    
    // Render a multi-select box
    return (
        <div id="filter-div">
            <select
                className="form-control select-column col-lg-3 col-md-6 col-sm-12 col-12 w-100 input-lg"
                size="sm"
                onChange={(e) => {
                    props.onFilterClick(e.target.value || undefined, props.id, props.subId || undefined);
                }}
            >
                <option value="">{props.text}: All</option>
                {options.map((option, i) => (
                    <option key={i} value={option} selected={selectedOption === capitalize(option)?true:""}>
                        {props.text}: {capitalize(option)}
                    </option>
                ))}
            </select>
        </div>
    );
};

function capitalize(str) {
    if ((!!str && typeof str === "string") || str instanceof String) return str[0].toUpperCase() + str.slice(1);
    else return str;
}
