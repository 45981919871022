import React, { useState, useEffect, useCallback, useReducer } from "react";
import axios from "axios";
import moment from "moment";
import Card from "./Layout/Card";
import LineChart from "components/Charts/LineChart";
import { useTranslation } from "react-i18next";
import CourierShareChart from "components/Charts/CourierShareChart";
import TopDestinationsChart from "components/Charts/TopDestinationsChart";
import MemberShipChart from "components/Charts/MemberShipChart";
import ScaleLoader from "react-spinners/ScaleLoader";
import * as config from "config/config";
import { API_CONSTANTS } from "constants/constants";
import DateRangePickerCustom from "components/Common/DateRangePicker/DateRangePickerCustom";
import { Container } from "react-bootstrap";
import { ThreeDotsVertical } from "react-bootstrap-icons";

const Dashboard = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    //Date Picker Logic
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    var token = localStorage.getItem("accessToken");
    var userWarehouse = localStorage.getItem("userWarehouse");
    
    useEffect(() => {
        if(!token){
            window.location.reload(false);
        }
    }, [token]);
    function reducer(state, action) {
        let destinationsLabel = [];
        let membershipsLabel = [];
        let responseData = action.response.data.payload;
        let parcelsData = [];
        if(action.apiUrl === API_CONSTANTS.TOP_DESTINATIONS && responseData){
            responseData.forEach(data => {
                if(data.country)
                    destinationsLabel.push(t(data.country))
            });
        }

        if(action.apiUrl === API_CONSTANTS.DASHBOARD_PARCELS && responseData){
            const pending = responseData.allReceivedParcels.filter(statusData => statusData.statusId === 3)
            const dispatched = responseData.allReceivedParcels.filter(statusData => statusData.statusId === 14)
            const delivered = responseData.allReceivedParcels.filter(statusData => statusData.statusId === 9)

            parcelsData.push(pending.length > 0?pending[0].count:0)
            parcelsData.push(dispatched.length > 0?dispatched[0].count:0)
            parcelsData.push(delivered.length > 0?delivered[0].count:0)
        }
        if(action.apiUrl === API_CONSTANTS.USER_INFO && responseData){
            responseData.breakdown.forEach(data => {
                if(data.count)
                    membershipsLabel.push(t(data['MembershipPlan.name']))
            });
        }

        switch (action.apiUrl) {
            case API_CONSTANTS.DASHBOARD_PARCELS:
                return {
                    ...state,
                    receivedParcels: responseData.receivedParcels
                        ? responseData.receivedParcels
                        : 0,
                    dispatchedParcels: parcelsData[1] && parcelsData[2]
                        ? parseInt(parcelsData[1]) + parseInt(parcelsData[2])
                        : parcelsData[1]? parseInt(parcelsData[1])
                        : parcelsData[2]? parseInt(parcelsData[2])
                        : 0,
                    pendingPayments: parcelsData[0],
                    consolidated: responseData.consolidated,
                    promoCodes: responseData?.promoCount,
                    suitesCount: responseData?.suitesCount,
                    dispatchedToday: responseData["getDispatchedToday"][0]?.count,
                };
            case API_CONSTANTS.USER_INFO:
                return {
                    ...state,
                    usersCount: responseData.countAllUsers,
                    memberShipCount: responseData.breakdown,
                    membershipLabel: membershipsLabel
                };
            case API_CONSTANTS.TOP_DESTINATIONS:
                return {
                    ...state,
                    topDestinations: responseData ? responseData : 0,
                    destinationLabel:  destinationsLabel
                };
            case API_CONSTANTS.COURIER_SHARE:
                return { ...state, courierShare: responseData.shares };
            case API_CONSTANTS.DASHBOARD_DAILY_RECIEVED_PARCELS: 
                return { ...state, parcelData: responseData };
            default:
                return;
        }
    }

    const [state, dispatch] = useReducer(reducer, {
        suitesCount: 0,
        receivedParcels: 0,
        usersCount: 0,
        dispatchedParcels: 0,
        pendingPayments: 0,
        promoCodes: [],
        memberShipCount: [],
        topDestinations: [],
        courierShare: [],
        parcelData: [],
        dispatchedToday: 0,
        consolidated: 0,
        destinationLabel: [],
        membershipLabel: []
    });

    async function fetchData(tokenConfig, dateObj2, userDateObj) {

        const parcelPresponse = await axios.post(config.apiUrl + API_CONSTANTS.DASHBOARD_PARCELS, dateObj2, tokenConfig);
        dispatch({ apiUrl: API_CONSTANTS.DASHBOARD_PARCELS, response: parcelPresponse });

        const userResponse = await axios.post(config.apiUrl + API_CONSTANTS.USER_INFO, userDateObj, tokenConfig);
        dispatch({ apiUrl: API_CONSTANTS.USER_INFO, response: userResponse });

        const dailyRecievedParcels = await axios.post(config.apiUrl + API_CONSTANTS.DASHBOARD_DAILY_RECIEVED_PARCELS, dateObj2, tokenConfig);
        dispatch({ apiUrl: API_CONSTANTS.DASHBOARD_DAILY_RECIEVED_PARCELS, response: dailyRecievedParcels });

        const destinationResponse = await axios.post(
            config.apiUrl + API_CONSTANTS.TOP_DESTINATIONS,
            dateObj2,
            tokenConfig
        );
        dispatch({ apiUrl: API_CONSTANTS.TOP_DESTINATIONS, response: destinationResponse });

        const courierResponse = await axios.post(config.apiUrl + API_CONSTANTS.COURIER_SHARE, dateObj2, tokenConfig);
        dispatch({ apiUrl: API_CONSTANTS.COURIER_SHARE, response: courierResponse });

       // setLoading(false);
    }

    const memoizedFetchData = useCallback(
        async (tokenConfig, dateObj2, userDateObj) => {
            await fetchData(tokenConfig, dateObj2, userDateObj);
        },
        [endDate]
    );

    useEffect(async () => {
        const dateObj2 = {
            start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : "2017-01-01",
            end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : moment().add(1,'days').format("YYYY-MM-DD"),
            warehouse_id: userWarehouse
        };
        const userDateObj = {
            start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
            end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
        };
        const token = localStorage.getItem("jwt_token");
        const tokenConfig = {
            headers: { Authorization: `Bearer ${token}` },
        };

        memoizedFetchData(tokenConfig, dateObj2, userDateObj);
    }, [endDate]);

    return (
        <>
            {loading || !token ? (
                <div className="loader row justify-content-center">
                    {" "}
                    <ScaleLoader color="#15397F" size={150} />{" "}
                </div>
            ) : (
                <Container fluid className="light-grey-background dashboard-container">
                    <div className="dash_heading row py-4 justify-content-start">
                        <div className="dash_title col-12 col-sm-5 col-md-7">
                            <p
                                style={{
                                    fontSize: "34px",
                                    fontWeight: "700",
                                    lineHeight: "41.15px",
                                    color: "#1A365D",
                                }}
                            >
                                {t("Dashboard")}
                            </p>
                        </div>
                        <div className="col-12 col-sm-5 col-md-4 w-auto ml-auto">
                        <DateRangePickerCustom setStartDate={setStartDate} setEndDate={setEndDate}
                            startDate={startDate ? moment(startDate).format("YYYY-MM-DD") : "2017-01-01"}
                            endDate={endDate ? moment(endDate).format("YYYY-MM-DD") : moment().add(1,'days').format("YYYY-MM-DD")} />
                        </div>
                    </div>

                    <div className="card_container row  hidden-md-up ">
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <Card info={state.receivedParcels} title={t("Received Parcels")} route="parcels" />
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <Card info={state.usersCount} title={t("Users")} route="users" />
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <Card info={state.suitesCount} title={t("Suites")} route="suites" />
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <Card info={state.dispatchedToday} title={t("Daily Dispatch")} />
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <Card info={state.dispatchedParcels} title={t("Total Dispatched")} route="dispatched" />
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <Card info={state.consolidated} title={t("Consolidations")} route="consolidated" />
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <Card info={state.pendingPayments} title={t("Payments Pending")} route="parcels/pending" />
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <Card info={state.promoCodes} title={t("Promo Codes")} route="promo-codes" />
                        </div>
                    </div>

                    <div className="row justify-content-between mb-2 p-x-15 charts-container">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-5 p-r-15 pl-0">
                            <div className="chart-shadow bg-white rounded d-flex flex-column inter-chart-container mid-container-height">
                                <div className="d-flex justify-content-between align-items-center my-2">
                                    <h3 className="chart-title">{t("Customer Breakdown")}</h3>
                                    {/* <ThreeDotsVertical className="chart-menu" /> */}
                                </div>
                                <MemberShipChart
                                    type="y"
                                    label="Membership"
                                    sidelabels={state.membershipLabel}
                                    data={state.memberShipCount}
                                    backgroundColor={["#1E88E5", "#153F90", "#FF7F5C"]}
                                />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-5 p-l-15 pr-0">
                            <div className="chart-shadow bg-white rounded d-flex flex-column inter-chart-container mid-container-height">
                                <div className="d-flex justify-content-between align-items-center my-2">
                                    <h3 className="chart-title">{t("Top 5 Destination Countries")}</h3>
                                    {/* <ThreeDotsVertical className="chart-menu" /> */}
                                </div>
                                <TopDestinationsChart
                                    type="y"
                                    labels={state.destinationLabel}
                                    data={state.topDestinations}
                                    backgroundColor={["#CE1126",  "#006C35","#fd0807", "#4189dd","#c09301"]}
                                />
                            </div>
                        </div>
                    </div>
                    {/******* COURIER SHARE BAR CHART *******/}
                    <div className="row justify-content-center mb-4">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-5">
                            <div className="chart-shadow bg-white rounded inter-chart-container full-container-height">
                                <div className="d-flex justify-content-between align-items-center my-2">
                                    <h3 className="chart-title">{t("Courier share")}</h3>
                                    {/* <ThreeDotsVertical className="chart-menu" /> */}
                                </div>
                                <CourierShareChart
                                    type="x"
                                    label="Courier share"
                                    data={state.courierShare}
                                    backgroundColor={["#452896", "#ED3124", "#005697", "#F0C103", "#005697", "#F0C103"]}
                                />
                            </div>
                        </div>
                    </div>

                    {/******* Received Parcels Line Chart *******/}
                    <div className="row justify-content-center mb-4">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-5">
                            <div className="chart-shadow bg-white rounded inter-chart-container full-container-height">
                                <div className="d-flex justify-content-between align-items-center my-2">
                                    <h3 className="chart-title">{t("Received Parcels")}</h3>
                                    {/* <ThreeDotsVertical className="chart-menu" /> */}
                                </div>

                                <LineChart label="Received Parcels" data={state.parcelData} />
                            </div>
                        </div>
                    </div>
                </Container>
            )}
        </>
    );
};

export default Dashboard;
