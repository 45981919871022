import React, { memo } from "react";
import ReceivedParcels from "../../assets/received_parcels.svg";
import User_logo from "../../assets/users.svg";
import Suites_logo from "../../assets/suits.svg";
import Dispatched from "../../assets/dispatch.svg";
import PendingPayment from "../../assets/payment_pending.svg";
import PromoCodes from "../../assets/promo_codes.svg";
import Consolidation from "../../assets/consolidation-card.svg";
import Daily_dispatched from "../../assets/daily_dispatch.svg";

import { Link } from "react-router-dom";

const Card = ({ info, title, route }) => {
    return (
        <Link style={{ textDecoration: "none" }} to={`/${route}`}>
            <div className="card">
                <div style={styles.textContainer}>
                    <p style={styles.number}>{info}</p>
                    <p style={styles.text}>{title}</p>
                </div>

                <div>
                    {title === "Received Parcels" ? (
                        <img style={styles.imgStyle} src={ReceivedParcels} alt="icon" />
                    ) : title === "Users" ? (
                        <img style={styles.imgStyle} src={User_logo} alt="icon" />
                    ) : title === "Suites" ? (
                        <img style={styles.imgStyle} src={Suites_logo} alt="icon" />
                    ) : title === "Daily Dispatch" ? (
                        <img style={styles.imgStyle} src={Daily_dispatched} alt="icon" />
                    ) : title === "Payments Pending" ? (
                        <img style={styles.imgStyle} src={PendingPayment} alt="icon" />
                    ) : title === "Promo Codes" ? (
                        <img style={styles.imgStyle} src={PromoCodes} alt="icon" />
                    ) : title === "Total Dispatched" ? (
                        <img style={styles.imgStyle} src={Dispatched} alt="icon" />
                    ) : title === "Consolidations" ? (
                        <img style={styles.imgStyle} src={Consolidation} alt="icon" />
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </Link>
    );
};

const styles = {
    cardContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        boxShadow: "0px 1px 2px rgba(31,41,55,0.08)",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
        marginBottom: "30px",
        padding: "5px 0px 10px 0px",
        height: 1
    },
    textContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "start",
    },
    number: {
        fontWeight: "600",
        fontSize: "34px",
        lineHeight: "32px",
        fontStyle: "normal",
        color: "#374151",
        fontFamily: "Inter",
        paddingTop: "10px",
    },
    text: {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "30px",
        fontStyle: "normal",
        color: "#2D3748",
        fontFamily: "Inter",
        display: "block",
    },
    imgStyle: {
        height: "80px",
    },
};

export default memo(Card);
