import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import Header from "./Layout/Header";
import InProcessParcelsTable from "components/InProcessParcels/InProcessParcelsTable";
import ScaleLoader from "react-spinners/ScaleLoader";
import * as config from "config/config";
import { API_CONSTANTS } from "constants/constants";
import DateRangePickerCustom from "components/Common/DateRangePicker/DateRangePickerCustom";

const InProcessParcels = () => {
    const [parcels, setParcels] = useState([]);
    const [loading, setLoading] = React.useState(true);

    //Date Picker Logic
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    var token = localStorage.getItem("accessToken");
    var userWarehouse = localStorage.getItem("userWarehouse");

    useEffect(() => {
        if(!token){
            window.location.reload(false);
        }
    }, [token]);
    
    useEffect(() => {
        const tokenConfig = {
            headers: { Authorization: `Bearer ${token}` },
        };
        const dateObj2 = {
            start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : "2020-04-15",
            end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : moment().add(1,'days').format("YYYY-MM-DD"),
            warehouse_id: userWarehouse
        };
        async function fetchData() {
            const parcelsResponse = await axios.post(config.apiUrl + API_CONSTANTS.IN_PROCESS_PARCELS, dateObj2, tokenConfig);
            const parcelsResponse1 = await axios.get(config.apiUrl + API_CONSTANTS.IN_PROCESS_PARCELS_COUNT + "?warehouse_id="+userWarehouse, tokenConfig);
            let tempParcels = parcelsResponse.data.payload.getAllParcels;
            setParcels(tempParcels);
            setLoading(false);
        }

        fetchData();
    }, [endDate]);
    return (
        <>
            {loading || !token ? (
                <div className="loader row justify-content-center">
                    <ScaleLoader color="#15397F" size={150} className=" mx-auto" />
                </div>
            ) : (
                <div className="parcels">
                    <Header
                        title="In Process Parcels"
                        records={parcels.length}
                        data={parcels}
                        name="Parcels"
                        buttonId="#parcelExportButton"
                    >
                        <div className="mx-2">
                        <DateRangePickerCustom setStartDate={setStartDate} setEndDate={setEndDate}
                            startDate={startDate ? moment(startDate).format("YYYY-MM-DD") : "2020-04-15"}
                            endDate={endDate ? moment(endDate).format("YYYY-MM-DD") : moment().add(1,'days').format("YYYY-MM-DD")} />
                        </div>
                    </Header>

                    <div className="row  d-flex">
                        <InProcessParcelsTable parcelsData={parcels} />
                    </div>
                </div>
            )}
        </>
    );
};

export default InProcessParcels;
