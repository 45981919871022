import React from "react";
const SizePerPageOptionRenderer = ({
    text,
    page,
    onSizePerPageChange
  }) => (
    <li
      key={ text }
      role="presentation"
      className="dropdown-item"
      onClick={ (e) => {
        e.preventDefault();
        onSizePerPageChange(page);
      } }
    >
      <a
        href="#"
        tabIndex="-1"
        role="menuitem"
        data-page={ page }
        onMouseDown={ (e) => {
          e.preventDefault();
          onSizePerPageChange(page);
        } }
        className={'pagination-per-page'}
      >
        { text }
      </a>
    </li>
  );

  export default SizePerPageOptionRenderer;
