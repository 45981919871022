import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import Header from "./Layout/Header";
import ScaleLoader from "react-spinners/ScaleLoader";
import PickSuite from "components/Pick/PickSuite";
import * as config from "config/config";
import { API_CONSTANTS } from "constants/constants";
import DateRangePickerCustom from "components/Common/DateRangePicker/DateRangePickerCustom";
import { useTranslation } from "react-i18next";
import { Tabs, Tab, Badge } from "react-bootstrap";
import PickBasicSuite from "components/Pick/PickBasicSuite";
const Pick = () => {
    const [picked, setPicked] = useState([]);
    const [pickedBasic, setPickedBasic] = useState([]);
    const [loading, setLoading] = React.useState(true);
    const [buttonId, setButtonId] = useState();

    //Date Picker Logic
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    var token = localStorage.getItem("accessToken");
    var userWarehouse = localStorage.getItem("userWarehouse");


    useEffect(() => {
        if(!token){
            window.location.reload(false);
        }
    }, [token]);
    const { t } = useTranslation();
    useEffect(() => {
        const dateObj2 = {
            start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : "2020-04-15",
            end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : moment().add(1,'days').format("YYYY-MM-DD"),
            warehouse_id: userWarehouse
        };
        const tokenConfig = {
            headers: { Authorization: `Bearer ${token}` },
        };
        async function fetchData() {
            const pickResponse = await axios.post(config.apiUrl + API_CONSTANTS.PICK, dateObj2, tokenConfig);
            setPicked(pickResponse.data.payload.picked);

            const pickParcelsResponse = await axios.post(
                config.apiUrl + API_CONSTANTS.PARCEL_PICK,
                dateObj2,
                tokenConfig
            );
            const pickParcelsRes = pickParcelsResponse.data.payload.pickParcels;
            setPickedBasic(pickParcelsRes);
            setLoading(false);
        }

        fetchData();
    }, [endDate]);

    return (
        <>
            {loading || !token ? (
                <div className="loader row justify-content-center">
                    <ScaleLoader color="#15397F" size={150} className=" mx-auto" />
                </div>
            ) : (
                <div className="pick-container">
                    <Header title={t("Pick")} records={picked.length+pickedBasic.length} data={picked} name="Pick" buttonId={buttonId}>
                        <div className="mx-2">
                        <DateRangePickerCustom setStartDate={setStartDate} setEndDate={setEndDate}
                            startDate={startDate ? moment(startDate).format("YYYY-MM-DD") : "2020-04-15"}
                            endDate={endDate ? moment(endDate).format("YYYY-MM-DD") : moment().add(1,'days').format("YYYY-MM-DD")} />
                        </div>
                    </Header>

                    <div className="row  d-flex tabs-data px-2">
                        <Tabs defaultActiveKey="suites" id="suites-tab" className="mt-4">
                            <Tab eventKey="suites" title={
                                <React.Fragment>
                                    {t("Suites")}
                                    <Badge className='mx-2 px-2'>{picked.length}</Badge>
                                </React.Fragment>}>
                                <PickSuite pickedData={picked} setButtonId={setButtonId} />
                            </Tab>
                            <Tab eventKey="basic" title={
                                <React.Fragment>
                                    {t("Basic")}
                                    <Badge className='mx-2 px-2'>{pickedBasic.length}</Badge>
                                </React.Fragment>}>
                                <PickBasicSuite pickedBasic={pickedBasic} setButtonId={setButtonId} />
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            )}
        </>
    );
};

export default Pick;
