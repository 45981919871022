import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../contexts/AuthContext";
import * as config from "../config/config";
import { API_CONSTANTS, GLOBAL_CONSTANTS } from "../constants/constants";
import axios from 'axios';
import {Row, Form, Col, Button, FormControl } from "react-bootstrap";

function Login() {
  const { t } = useTranslation();
  const imgSrc = `/images/Logo.svg`;
  const [validated, setValidated] = useState(false);
  const [loginUser, setLoginUser] = useState({
    email: "",
    password: "",
  });
  const [rememberMeBool, setRememberMeBool] = useState(false);
  const [error, setError] = useState(null);

  const { setAuthToken, setUsername, setIsEmailVerified, setUserEmail, setUserWarehouse } = useAuth();

  let name, value;

  const handleInputs = (event) => {
    setError(null)
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault()
    const formData = new FormData(event.target),
          formDataObj = Object.fromEntries(formData.entries())
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setValidated(true);
    try {
            axios.post(config.apiUrl + API_CONSTANTS.ADMIN_LOGIN,  formDataObj )
            .then(async res => {
              //user pin not used so far
              if (res?.data.status === false) {
                setError(res?.data.message);
              }
              else {
                const { name, accessToken, is_verified } = res?.data;
  
                if (name) {
                    await setUsername(name);
                }
                if (accessToken) {
                    await setAuthToken(accessToken);
                }
                await setIsEmailVerified(is_verified || false);
                await setUserEmail(loginUser.email);
                await setUserWarehouse(GLOBAL_CONSTANTS.DEFAULT_WAREHOUSE.id);
              }
            })
       
      
    } catch (e) {
      setError(e.message);
    }
  };

  return (
    <>
    <div className="text-center pt-3">
      <img
        className={"logo m-auto"}
        src={imgSrc}
        alt={t("Logo")}
      />
      <div className="logo-text my-4">
        {t('Sign_In_Text')}
      </div>
    </div>
    <div className="container-fluid p-4 w-100">
        
        <Form validated={validated} onSubmit={handleSubmit} className="form">
            <Row>
             <Col  xs={12} sm={12} md={12} lg={12}>
                <Form.Group className="mt-2" controlId="Email">
                  <Form.Label className="mb-3">{t('Email')}</Form.Label>
                    <Form.Control 
                        type="text"
                        placeholder={t("Email_Placeholder")}
                        name="username"
                        required={true}
                        onChange={handleInputs}
                    />
                    <FormControl.Feedback type="invalid">
                         {t("Email_Error")}
                    </FormControl.Feedback>
              </Form.Group>
             </Col>
           </Row>
           <Row>
             <Col  xs={12} sm={12} md={12} lg={12}>
                <Form.Group className="mt-2" controlId="Password">
                  <Form.Label className="mb-3">{t('Password')}</Form.Label>
                    <Form.Control 
                        type="password"
                        placeholder={t("Password_Placeholder")}
                        name="password"
                        required={true}
                        onChange={handleInputs}
                    />
                    <FormControl.Feedback type="invalid">
                         {t("Password_Error")}
                    </FormControl.Feedback>
              </Form.Group>
             </Col>
           </Row>
           <Row >
              <div className="error">{error}</div>
            </Row>
           <Row className="my-3">
              <Col  xs={12} sm={12} md={12} lg={12} className="text-right">
                  <Button type="submit" className="submit-button">
                    {t('Login')}
                  </Button>
              </Col>
            </Row>
            
        </Form>
    </div>
  </>
  );
}

export default Login;
