import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useTranslation } from "react-i18next";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { SearchBar, SelectFilterColums } from "components/Tables/Columns";
import { Col, Row } from "react-bootstrap";
import pageListRenderer from "../Tables/PageListRenderer";
import sizePerPageOptionRenderer from "../Tables/SizePerPageOptionRenderer";
import { logoUrl } from "config/config";
import CustomTotal from "../Tables/CustomTotal";
import moment from "moment";
import ChargeableWeight from "../Tables/ChargeableWeight"
import GetVolumeWeight from "../Tables/GetVolumeWeight";
import { GLOBAL_CONSTANTS } from "constants/constants";

let parcelfilter, userNameFilter;

function PickBasicSuite(props) {
    const { t } = useTranslation();

    const onFilter = (value, id, subId) => {
        if (!value) value = "";
        if (id === "uuid") parcelfilter(value);
        else if(id === 'user') userNameFilter(value);
    };

    props.setButtonId("#pickBasicExportButton");

    const pickBasicParcelsColumns = [
        {
            dataField: "created_at",
            text: t('Date'),
            sort:true,
            style: {
                width: 100,
            },
            formatter: (cell, row) => {
              if(cell)
                return moment(cell).utc().format("DD MMM yyyy");
            },
          },
        {
            dataField: "uuid",
            text: t("Parcel ID"),
            classes: "highlighted-text",
            filter: textFilter({
                getFilter: (filter) => {
                    parcelfilter = filter;
                },
            }),
        },
        {
            text: t("AWB"),
            dataField: "awb",
        },
        {
            text: t("Items"),
            dataField: "ParcelHasItems",
            formatter: (cell, row) => {
                let quantity = 0
                cell.forEach(parcelItem => {
                  quantity += parcelItem.quantity
                });
                return quantity
            },
            csvFormatter: (cell, row) => {
                let quantity = 0
                cell.forEach(parcelItem => {
                  quantity += parcelItem.quantity
                });
                return quantity
            },
        },
        {
            text: t('Customer'),
            dataField: "user.country",
            formatter: (cell, row) => {
              return <div>
                <span className="d-flex">{row.user.user_pin}</span>
                <span className="font-12">{row.user.name}</span></div>;
            },
            filter: textFilter({
              getFilter: (filter) => {
                userNameFilter = filter;
              }
            }),
            csvFormatter: (cell, row) => {
              return row.user.user_pin +" ,"+ row.user.name;
            },
          },
          {
            text: t('Customer_Pin'),
            dataField: "user.user_pin",
            hidden:true,
            csvExport:false
          },
          {
            text: t('Customer_Name'),
            dataField: "user.name",
            hidden:true,
            csvExport:false
          },
          {
            text: t("Suite"),
            dataField: "ParcelSuite.suite.suite",
            classes: "highlighted-text",
            formatter: (cell) => {
                return cell?cell:'-';
            },
            csvFormatter: (cell, row) => {
                return cell?cell:'-';
            },
          },
        {
            text: t("Destination"),
            dataField: "ParcelAddress.country",
        },
        {
            text: t("A_Wt"),
            dataField: "weight_value",
            formatter: (cell, row) => {
                return (
                    cell ?
                    <div>
                        <strong>{cell}</strong> {row.weight_unit}
                    </div>
                :
                <div>
                    <strong>{0}</strong>
                </div>);
            }
        },
        {
            dataField: "dimension_height",
            text: t("Ch_Wt"),
            sort: true,
            formatter: (cell, row) => {
                const actualWeight = row.weight_value;
                const VolumeWeight = GetVolumeWeight(row);
                const chargeableWeight = ChargeableWeight(VolumeWeight, actualWeight);
                return (
                chargeableWeight ?
                    <div>
                        <strong>{chargeableWeight?.toFixed(2)}</strong> {row.weight_unit}
                    </div>
                :
                <div>
                    <strong>{0}</strong>
                </div>);
            },
            csvFormatter: (cell, row) => {
                const actualWeight = row.weight_value;
                const VolumeWeight = GetVolumeWeight(row);
                const chargeableWeight = ChargeableWeight(VolumeWeight, actualWeight);
                return chargeableWeight?.toFixed(2);
            },
        },
        {
            dataField: 'dimension_length',
            text: t('Dimensions'),
            formatter: (cell, row) => {
                const w = row.dimension_width;
                const h = row.dimension_height;
                
                return cell?cell+" x "+w+" x "+h+"  "+row.dimensions_unit:'-';
            },
        },
        {
            dataField: "amount",
            text: t("Fees"),
            sort: true,
            formatter: (cell, row) => {
                return (
                    <div>
                        <strong>{cell}</strong> {row.currency}
                    </div>
                );
            },
            csvFormatter: (cell, row) => {
                return cell +" "+row.currency;
            },
        },
        {
            text: t('Status'),
            sort:true,
            dataField: "ParcelHasParcelStatus.ParcelStatus.name",
            formatter: (cell, row) => {
              return (cell)
                ?<div className={cell.toLowerCase().replace(/[^a-zA-Z ]/g, " ").split(' ').join('-')}>{cell}</div>
                :""
            },
          },
        {
            dataField: "ShippingCompany.logo",
            text: t("Shipping"),
            formatter: (cell) => {
                if (cell) return <img width="120" height="80" src={logoUrl + cell} alt="shipping_company" />;
            },
        },
    ];
    const options = {
        sizePerPage: GLOBAL_CONSTANTS.SIZE_PER_PAGE,
        hideSizePerPage: false,
        hidePageListOnlyOnePage: true,
        sizePerPageOptionRenderer,
        showTotal: true,
        pageListRenderer,
        paginationTotalRenderer: CustomTotal,
    };

    const { ExportCSVButton } = CSVExport;

    return (
        <div className="table-responsive">
            {pickBasicParcelsColumns && (
                <ToolkitProvider
                    keyField="id"
                    data={props.pickedBasic}
                    columns={pickBasicParcelsColumns}
                    search={{ searchFormatted: true }}
                    exportCSV={{
                        fileName: "PickBasicTable.csv",
                        noAutoBOM: false,
                        exportAll: false,
                        onlyExportFiltered: true,
                    }}
                >
                    {(props) => (
                        <div className="table-container my-4">
                            <Row className="search mx-2 justify-content-end">
                                <Col xs={5} sm={4} md={3} lg={2} className="d-flex justify-content-end">
                                    <SelectFilterColums
                                        preFilteredRows={props.baseProps.data}
                                        id="uuid"
                                        text={t("Parcel ID")}
                                        {...props.searchProps}
                                        onFilterClick={onFilter}
                                    />
                                </Col>
                                <Col xs={5} sm={4} md={3} lg={3} className="search-width">
                                    <SearchBar {...props.searchProps} />
                                </Col>
                                <Col className="d-none">
                                    <ExportCSVButton {...props.csvProps} id="pickBasicExportButton">
                                        Export
                                    </ExportCSVButton>
                                </Col>
                            </Row>
                            <Row className="table-data my-3">
                                <Col>
                                    <BootstrapTable
                                        {...props.baseProps}
                                        wrapperClasses="table-wrapper my-3"
                                        headerClasses="header-class"
                                        bordered={false}
                                        noDataIndication={t("No_Record")}
                                        filter={filterFactory()}
                                        rowClasses="custom-table-row pick-basic"
                                        condensed={true}
                                        pagination={paginationFactory(options)}
                                    />
                                </Col>
                            </Row>
                        </div>
                    )}
                </ToolkitProvider>
            )}
        </div>
    );
}

export default PickBasicSuite;
