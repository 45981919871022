import React from "react";
import { Col  } from "react-bootstrap";

const PageListRenderer = ({
    pages,
    onPageChange,
  }) => {
    const pageWithoutIndication = pages.filter(p => typeof p.page === 'string');
    return (
      <Col xs={12} sm={12} md={6} lg={6} className="text-end">
        {
          pageWithoutIndication.map(p => {
            return (
            <button className="btn next-previous-icon" key={p.page}
            onClick={ () => onPageChange(p.page) }>{ 
              (p.page === '>')?<i className="bx bx-chevron-right" ></i>:
              (p.page === '<')?<i className="bx bx-chevron-left"></i>: 
              (p.page === '>>')?<i className="bx bx-chevrons-right"></i>:
              (p.page === '<<')?<i className="bx bx-chevrons-left"></i>: p.page }</button>
          )})
        }
      </Col>
    );
  };

  export default PageListRenderer;
