import React from "react";

function GetVolumeWeight(row) {
    let VolumeWeight = 0;
    const l = row.dimension_length;
    const w = row.dimension_width;
    const h = row.dimension_height;
    const unit = row.dimensions_unit;
    if(h && w && l && (unit === 'cm' || unit === 'CM'))
        VolumeWeight =  (l * w * h) / 5000;
    else
        VolumeWeight =  (l * w * h) / 139;

    return VolumeWeight;
};
export default GetVolumeWeight;
