import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import { useTranslation } from "react-i18next";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Col, Row  } from "react-bootstrap";
import filterFactory from 'react-bootstrap-table2-filter';
import { SearchBar, SelectFilterColums } from "components/Tables/Columns";
import { textFilter } from 'react-bootstrap-table2-filter';
import moment from "moment";
import { ChevronUp, ChevronDown } from 'react-bootstrap-icons';
import pageListRenderer from '../Tables/PageListRenderer';
import sizePerPageOptionRenderer from '../Tables/SizePerPageOptionRenderer';
import CustomTotal from "../Tables/CustomTotal";
import { LOGIN_CONSTANTS } from "constants/constants";
import { GLOBAL_CONSTANTS } from "constants/constants";

let planTypeFilter,userFilter, userCountryFilter;

function UsersTable(props) {
  const { t } = useTranslation();

  const onFilter = (value, id) => {
    if(!value)
      value = '';
    if(id === 'MembershipPlan')
      planTypeFilter(value);
    else if(id === 'user_pin')
      userFilter(value);
    else if(id === 'country')
      userCountryFilter(value);
  };

 const usersColumns = [
  {
    dataField: "created_at",
    text: t('Signup_Date'),
    formatter: (cell, row) => {
      if(cell)
        return moment(cell).utc().format("DD MMM yyyy");
    },
    sort: true,
    csvFormatter: (cell, row) => {
      if(cell)
        return moment(cell).utc().format("DD MMM yyyy");
    },
  },
  {
    dataField: "email_verified_at",
    text: t('Verify_Date'),
    formatter: (cell, row) => {
      if(cell)
        return moment(cell).utc().format("DD MMM yyyy");
    },
    sort: true,
    csvFormatter: (cell, row) => {
      if(cell)
        return moment(cell).utc().format("DD MMM yyyy");
    },
  },
  {
    text: t('User_Id'),
    dataField: "user_pin",
    filter: textFilter({
      getFilter: (filter) => {
        userFilter = filter;
      }
    })
  },
  {
    text: t('Plan_Type'),
    filter: textFilter({
      getFilter: (filter) => {
        planTypeFilter = filter;
      }
    }),
    dataField: "MembershipPlan.name",
    classes: 'membership_plan',
    formatter: (cell, row) => {
      return cell.toLowerCase() === "Basic".toLocaleLowerCase() ? (
        <div className="plan_basic">
          {t('Basic')}
        </div>
      ) : cell.toLowerCase() === "Business".toLocaleLowerCase() ? (
        <div className="plan_business">
          {t('Business')}
        </div>
      ) : cell.toLowerCase() === "Premium".toLocaleLowerCase() ? (
        <div className="plan_premium">
          {t('Premium')}
        </div>
      ) : (
        ""
      );
    },
  },
  {
    text: t('Email'),
    dataField: "email",
  },
  {
    text: t('Name'),
    dataField: "name",
    csvFormatter: (cell, row) => {
      if(cell)
        return `${cell}`;
    },
  },
  {
    text: t('Phone'),
    dataField: "phone",
    formatter: (cell, row) => {
      if(cell)
        return cell;
      else{
        if(row.login_via === "google")
          return <span className="google-color">{ LOGIN_CONSTANTS.GOOGLE }</span>;
        else if(row.login_via === "facebook")
          return <span className="facebook-color">{ LOGIN_CONSTANTS.FACEBOOK }</span>;
        else if(row.login_via === "email")
          return cell;
        else 
          return <span className="apple-color">{ LOGIN_CONSTANTS.APPLE }</span>;
      }
    },
    csvFormatter: (cell, row) => {
      if(cell)
        return cell;
      else{
        if(row.login_via === "google")
          return LOGIN_CONSTANTS.GOOGLE;
        else if(row.login_via === "facebook")
          return LOGIN_CONSTANTS.FACEBOOK;
        else if(row.login_via === "email")
          return cell;
        else 
          return LOGIN_CONSTANTS.APPLE;
      }
    },
  },
  {
    text: t('Parcels'),
    dataField: 'parcels',
    sort: true,
    formatter: (cell,row) => {
      return cell.length;
    },
    csvFormatter: (cell, row) => {
      return cell?cell.length:'-'
    },
  },
  {
    text: t('Country'),
    dataField: "country",
    filter: textFilter({
      getFilter: (filter) => {
        userCountryFilter = filter;
      }
    }),
  },
  {
    text: t('Credit User'),
    dataField: "is_credit_user",
    formatter: (cell, row) => {
        if(cell === 1)
          return <span className="google-color">{t('Yes')}</span>;
        else 
          return <span className="apple-color">{t('no')}</span>;
    },
    csvFormatter: (cell, row) => {
      return cell === 1 
      ? t('Yes')
      : t('no')
    },
  },
  {
    text: t('Status'),
    dataField: "status",
    formatter: (cell) => {
      return cell === 1 ? (
        <span className="status">
          {t('Active')}
        </span>
      ) : (
        <button disabled> {t('Inactive')} </button>
      );
    },
    csvFormatter: (cell, row) => {
      return cell === 1 
      ? t('Active')
      : t('Inactive')
    },
  },
  {
    dataField: 'fname',
    text: t('First_Name'),
    hidden: true,
    csvFormatter: (cell, row) => {
        return cell?cell:'-';
    },
  },
  {
    dataField: 'lname',
    text: t('Last_Name'),
    hidden: true,
    csvFormatter: (cell, row) => {
        return cell?cell:'-';
    },
  },
  {
    dataField: 'UsersAddresses[0].country',
    text: t('Shipping_Country'),
    hidden: true,
    csvFormatter: (cell, row) => {
        return cell?cell:'-';
    },
  },
  {
    dataField: 'UsersAddresses[0].address',
    text: t('Shipping_Address_Line1'),
    hidden: true,
    csvFormatter: (cell, row) => {
        return cell?cell:'-';
    },
  },
  {
    dataField: 'UsersAddresses[0].address2',
    text: t('Shipping_Address_Line2'),
    hidden: true,
    csvFormatter: (cell, row) => {
        return cell?cell:'-';
    },
  },
  {
    dataField: 'UsersAddresses[0].city',
    text: t('Shipping_City'),
    hidden: true,
    csvFormatter: (cell, row) => {
        return cell?cell:'-';
    },
  },
  {
    dataField: 'UsersAddresses[0].postal_code',
    text: t('Shipping_Postal_Code'),
    hidden: true,
    csvFormatter: (cell, row) => {
        return cell?cell:'-';
    },
  },
];

const expandRow = {
  renderer: row => {
    const addressData = row.UsersAddresses && row.UsersAddresses.filter(data => data.is_primary ===  1);
    const address = addressData.length > 0 ? addressData[0]: ''
    return (
    <Row className='expanding-row px-4 py-4'>
      <Col xs={12} sm={12} md={12} lg={5}>
        <div className="heading">{t('Personal_Information')}</div>
        <Row className="expand-data">
          <Col xs={12} sm={12} md={12} lg={4} className="label">{t('First_Name')}</Col>
          <Col xs={12} sm={12} md={12} lg={8} className="value">{row.fname}</Col>
        </Row>
        <Row className="expand-data">
          <Col xs={12} sm={12} md={12} lg={4} className="label">{t('Last_Name')}</Col>
          <Col xs={12} sm={12} md={12} lg={8} className="value">{row.lname}</Col>
        </Row>
        <Row className="expand-data">
          <Col xs={12} sm={12} md={12} lg={4} className="label">{t('Email')}</Col>
          <Col xs={12} sm={12} md={12} lg={8} className="value">{row.email}</Col>
        </Row>
        <Row className="expand-data">
          <Col xs={12} sm={12} md={12} lg={4} className="label">{t('Password')}</Col>
          <Col xs={12} sm={12} md={12} lg={8} className="value">**************</Col>
        </Row>
        <Row className="expand-data">
          <Col xs={12} sm={12} md={12} lg={4} className="label">{t('Status')}</Col>
          <Col xs={12} sm={12} md={12} lg={8} className="value">{row.status === 1?t('Active'):'inactive'}</Col>
        </Row>
      </Col>
      <Col xs={12} sm={12} md={12} lg={7}>
        <div className="heading">{t('Home_Shipping_Address')}</div>
        <Row className="expand-data">
          <Col xs={12} sm={12} md={12} lg={4} className="label">{t('City')}</Col>
          <Col xs={12} sm={12} md={12} lg={8} className="value">{(address)?address.city: '-'}</Col>
        </Row>
        <Row className="expand-data">
          <Col xs={12} sm={12} md={12} lg={4} className="label">{t('Postal_Code')}</Col>
          <Col xs={12} sm={12} md={12} lg={8} className="value">{(address)?address.postal_code: '-'}</Col>
        </Row>
        <Row className="expand-data">
          <Col xs={12} sm={12} md={12} lg={4} className="label">{t('Address_Line1')}</Col>
          <Col xs={12} sm={12} md={12} lg={8} className="value">{(address)?address.address: '-'}</Col>
        </Row>
        <Row className="expand-data">
          <Col xs={12} sm={12} md={12} lg={4} className="label">{t('Address_Line2')}</Col>
          <Col xs={12} sm={12} md={12} lg={8} className="value">{(address)?address.address2: '-'}</Col>
        </Row>
        <Row className="expand-data">
          <Col xs={12} sm={12} md={12} lg={4} className="label">{t('Phone')}</Col>
          <Col xs={12} sm={12} md={12} lg={8} className="value">{(address)?address.phone: '-'}</Col>
        </Row>
      </Col>
    </Row>
  )},
  showExpandColumn: true,
  expandByColumnOnly: true,
  expandHeaderColumnRenderer: ({ isAnyExpands }) => {
    return '';
  },
  expandColumnRenderer: ({ expanded }) => {
    if (expanded) {
      return (
        <ChevronUp color="#2D3748" size={10} className="mx-2 my-3 arrow-icon" />
      );
    }
    return (
      <ChevronDown color="#2D3748" size={10} className="mx-2 my-3 arrow-icon" />
    );
  }
};


  const options = {
    sizePerPage: GLOBAL_CONSTANTS.SIZE_PER_PAGE,
    hideSizePerPage: false,
    hidePageListOnlyOnePage: true,
    sizePerPageOptionRenderer,
    showTotal: true,
    pageListRenderer,
    paginationTotalRenderer: CustomTotal,
  };

  const { ExportCSVButton } = CSVExport;

  return (
    <div className="table-responsive">
      {usersColumns &&
        <ToolkitProvider
          keyField="user_pin"
          data={ props.usersData }
          columns={ usersColumns }
          search={ { searchFormatted: true } }
          exportCSV={{
                        fileName: "UsersTable.csv",
                        noAutoBOM: false,
                        exportAll: false,
                        blobType: 'text/csv;charset=utf-8', 
                        onlyExportFiltered: true,
          }}
        >
          {
            props => (
              <div className="table-container my-4">
                <Row className="search mx-2 users-search">
                  <Col xs={12} sm={12} md={12} lg={9}>
                    <Row>
                      <Col xs={12} sm={6} md={6} lg={3} className="user-select-column">
                        <SelectFilterColums preFilteredRows={props.baseProps.data} id="MembershipPlan" subId="name" text={t("Plan_Type")} { ...props.searchProps } onFilterClick={onFilter}/>
                      </Col>
                      <Col xs={12} sm={6} md={6} lg={3}>
                        <SelectFilterColums preFilteredRows={props.baseProps.data} id="user_pin" text={t("Account_Id")} { ...props.searchProps }  onFilterClick={onFilter}/>
                      </Col>
                      <Col xs={12} sm={6} md={6} lg={3}>
                        <SelectFilterColums preFilteredRows={props.baseProps.data} id="country" text={t("Country")} { ...props.searchProps }  onFilterClick={onFilter}/>
                      </Col>
                      <Col xs={12} sm={6} md={6} lg={3}>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={3} className="p-x-32">
                    <SearchBar { ...props.searchProps } />
                  </Col>
                  <Col className="d-none">
                    <ExportCSVButton {...props.csvProps} id="usersExportButton">
                        Export
                    </ExportCSVButton>
                  </Col>
                </Row>
                <Row className="table-data my-3">
                  <Col>
                    <BootstrapTable
                      expandRow={ expandRow }
                      { ...props.baseProps }
                      wrapperClasses="table-wrapper my-3 "
                      headerClasses="header-class"
                      bordered={ false }
                      noDataIndication={t("No_Record")}
                      filter={ filterFactory() }
                      rowClasses="custom-table-row"
                      condensed={true}
                      pagination={ paginationFactory(options) }
                      sort={ { dataField: 'email_verified_at', order: 'desc' } }
                    />
                  </Col>
                </Row>
              </div>
            )
          }
        </ToolkitProvider>
      }
    </div>
  );
}

export default UsersTable;
