import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import UsersTable from "components/Users/UsersTable";
import Header from "./Layout/Header";
import ScaleLoader from "react-spinners/ScaleLoader";
import * as config from "config/config";
import { API_CONSTANTS } from "constants/constants";
import { useTranslation } from "react-i18next";
import DateRangePickerCustom from "components/Common/DateRangePicker/DateRangePickerCustom";

const Users = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = React.useState(true);

    //Date Picker Logic
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const { t } = useTranslation();
    
    var token = localStorage.getItem("accessToken")
    useEffect(() => {
        if(!token){
            window.location.reload(false);
        }
    }, [token]);
    useEffect(() => {
        
        const dateObj2 = {
            start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : moment().subtract(1, 'months').format('YYYY-MM-DD'),
            end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : moment().add(1,'days').format("YYYY-MM-DD"),
        };
        const tokenConfig = {
            headers: { Authorization: `Bearer ${token}` },
        };
        async function fetchData() {
            const usersResponse = await axios.post(config.apiUrl + API_CONSTANTS.ALL_USERS, dateObj2, tokenConfig);
            setUsers(usersResponse.data.payload.allUsers);
            setLoading(false);
        }

        fetchData();
    }, [endDate]);
    return (
        <>
            {loading || !token ? (
                <div className="loader row justify-content-center">
                    <ScaleLoader color="#15397F" size={150} className=" mx-auto" />
                </div>
            ) : (
                <div className="users-container">
                    <Header title={t("Users")} records={users.length} data={users} name="Users" buttonId="#usersExportButton">
                        <div className="mx-2">
                        <DateRangePickerCustom setStartDate={setStartDate} setEndDate={setEndDate}
                            startDate={startDate ? moment(startDate).format("YYYY-MM-DD") : moment().subtract(1, 'months').format('YYYY-MM-DD')}
                            endDate={endDate ? moment(endDate).format("YYYY-MM-DD") : moment().add(1,'days').format("YYYY-MM-DD")} />
                        </div>
                    </Header>

                    <div className="row  d-flex">
                        <UsersTable usersData={users} />
                    </div>
                </div>
            )}
        </>
    );
};

export default Users;
