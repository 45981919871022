import { createContext, useState, useContext, useEffect } from "react";
import { GLOBAL_CONSTANTS } from "constants/constants";

export const AuthContext = createContext({});

const AuthProvider = (props) => {
  let authTokenFromLocalStorage;
  let usernameFromLocalStorage;
  let userEmailFromLocalStorage;
  let isEmailVerifiedFromLocalStorage;
  let userWarehouseFromLocalStorage;

  try {
    authTokenFromLocalStorage = JSON.parse(
      JSON.stringify(localStorage.getItem("accessToken"))
    );
    usernameFromLocalStorage = JSON.parse(
      JSON.stringify(localStorage.getItem("username"))
    );
    userEmailFromLocalStorage = JSON.parse(
      JSON.stringify(localStorage.getItem("userEmail"))
    );
    isEmailVerifiedFromLocalStorage = JSON.parse(
      JSON.stringify(localStorage.getItem("isEmailVerified"))
    );
    userWarehouseFromLocalStorage = JSON.parse(
      JSON.stringify(localStorage.getItem("userWarehouse"))
    );
  } catch (e) {
    console.log(e);
  }

  const [loggedIn, setLoggedIn] = useState(false);
  const [authToken, setAuthToken] = useState(authTokenFromLocalStorage || "");
  const [username, setUsername] = useState(usernameFromLocalStorage || "");
  const [userEmail, setUserEmail] = useState(userEmailFromLocalStorage || "");
  const [isEmailVerified, setIsEmailVerified] = useState(isEmailVerifiedFromLocalStorage || false);
  const [userWarehouse, setUserWarehouse] = useState(userWarehouseFromLocalStorage || GLOBAL_CONSTANTS.DEFAULT_WAREHOUSE.id);

  useEffect(() => {
    if (authToken && username) {
      login();
    }
  }, []);

  useEffect(() => {
    if(!authToken) return;
    localStorage.setItem("accessToken", authToken);
  }, [authToken]);

  useEffect(() => {
    if(!username) return;
    localStorage.setItem("username", username);
  }, [username]);

  useEffect(() => {
    if(!userEmail) return;
    localStorage.setItem("userEmail", userEmail);
  }, [userEmail]);

  useEffect(() => {
    localStorage.setItem("isEmailVerified", isEmailVerified);
  }, [isEmailVerified]);

  useEffect(() => {
    localStorage.setItem("userWarehouse", userWarehouse);
  }, [userWarehouse]);

  const login = () => {
    setLoggedIn(true);
  };

  const logout = () => {
    //clear the user data
    setLoggedIn(false);
    setAuthToken(null);
    setUsername(null);
    setIsEmailVerified(false);
    setUserWarehouse(null)
    localStorage.clear();
  };

  const authContextValue = {
    login,
    logout,
    loggedIn,
    authToken,
    setAuthToken,
    username,
    setUsername,
    userEmail,
    setUserEmail,
    isEmailVerified,
    setIsEmailVerified,
    setUserWarehouse,
    userWarehouse
  };

  return <AuthContext.Provider value={authContextValue} {...props} />;
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
