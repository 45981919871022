import React from "react";

function ParcelItems(cell, row) {
    let quantity = 0;
    if(cell && cell.length > 0){
        cell.forEach(parcelItems => {
            parcelItems.ParcelHasItems.forEach(parcelItem => {
                quantity += parcelItem.quantity
            });
            // quantity += parcelItems.ParcelHasItems.length
        });
    }
    // else {
    //    quantity = row.ParcelHasItems.length
    //}
    return quantity;
};
export default ParcelItems;
