import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import SuitesTable from "components/Suites/SuitesTable";
import Header from "./Layout/Header";
import ScaleLoader from "react-spinners/ScaleLoader";
import * as config from "config/config";
import { API_CONSTANTS } from "constants/constants";
import DateRangePickerCustom from "components/Common/DateRangePicker/DateRangePickerCustom";

const Suites = () => {
    const [suites, setSuites] = useState([]);
    const [loading, setLoading] = React.useState(true);

    //Date Picker Logic
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    var token = localStorage.getItem("accessToken");
    var userWarehouse = localStorage.getItem("userWarehouse");

    useEffect(() => {
        if(!token){
            window.location.reload(false);
        }
    }, [token]);
    useEffect(() => {
        const dateObj2 = {
            start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : "2020-04-15",
            end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : moment().add(1,'days').format("YYYY-MM-DD"),
            warehouse_id: userWarehouse
        };
        let tokenConfig = {
            headers: { Authorization: `Bearer ${token}` },
        };
        async function fetchData() {
            const suitesResponse = await axios.post(config.apiUrl + API_CONSTANTS.ALL_SUITES, dateObj2, tokenConfig);
            setSuites(suitesResponse.data.payload.allSuites);
            setLoading(false);
        }

        fetchData();
    }, [endDate]);
    return (
        <>
            {loading || !token ? (
                <div className="loader row justify-content-center">
                    <ScaleLoader color="#15397F" size={150} className=" mx-auto" />
                </div>
            ) : (
                <div className="suites-container">
                    <Header title="Suites" records={suites.length} data={suites} name="Suites" buttonId="#suitesExportButton">
                        <div className="mx-2">
                        <DateRangePickerCustom setStartDate={setStartDate} setEndDate={setEndDate}
                            startDate={startDate ? moment(startDate).format("YYYY-MM-DD") : "2020-04-15"}
                            endDate={endDate ? moment(endDate).format("YYYY-MM-DD") : moment().add(1,'days').format("YYYY-MM-DD")} />
                        </div>
                    </Header>

                    <div className="row d-flex px-2">
                        <SuitesTable suitesData={suites} />
                    </div>
                </div>
            )}
        </>
    );
};

export default Suites;
