export const API_CONSTANTS = {
  "ALL_USERS": "/api/all-users",
  "CONSOLIDATED": "/api/consolidated",
  "DASHBOARD_SUITES_COUNT": "/api/get-dashboard-suites-count",
  "DASHBOARD_PARCELS": "/api/get-dashboard-parcels-detail",
  "USER_INFO":"/api/users-info",
  "DASHBOARD_PROMO_CODES_COUNT": "/api/get-dashboard-promo-code-count",
  "TOP_DESTINATIONS": "/api/top-destinations",
  "COURIER_SHARE": "/api/courier-share",
  "DISPATCHED_PARCELS": "/api/dispatched-parcels",
  "DROP": "/api/drop",
  "ALL_PARCELS": "/api/all-parcels",
  "PICK": "/api/pick",
  "PARCEL_PICK": "/api/pick-parcel",
  "ALL_PROMO_CODES": "/api/all-promo-codes",
  "ALL_SUITES": "/api/all-suites",
  "ADMIN_LOGIN": "/adminlogin",
  "IN_PROCESS_PARCELS": "/api/inprocess-parcels",
  "IN_PROCESS_PARCELS_COUNT": "/api/inprocess-parcels-count",
  "GET_ALL_WAREHOUSES": "/api/get-all-warehouses",
  "DASHBOARD_DAILY_RECIEVED_PARCELS": "/api/get-dashboard-daily-recieved-parcels",
};


export const LOGIN_CONSTANTS = {
  GOOGLE: "Login Via Google",
  FACEBOOK: "Login Via Facebook",
  APPLE: "Login Via Apple"
}

export const GLOBAL_CONSTANTS = {
  INVOICE_DOWNLOAD_URL: `https://parcels.shopiniworld.com/api/parcels/get-invoice/`,
  DEFAULT_WAREHOUSE: {
    id: 1,
    name: 'United States',
    country: 'US'
  },
  SIZE_PER_PAGE: 25
} 