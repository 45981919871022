import React from "react";
import GetVolumeWeight from "./GetVolumeWeight";
import ChargeableWeight from "./ChargeableWeight";

function ConsolidationChargeableWeight(row) {
    let chargeableWeight = 0;
    const actualWeight = row.weight_value;
    const VolumeWeight = GetVolumeWeight(row);
    chargeableWeight = ChargeableWeight(VolumeWeight, actualWeight);

    if(row.parcel_children.length > 0) {
        row.parcel_children.forEach(parcel => {
            if (parcel.uuid.match(/BC.*/) || parcel.uuid.match(/IC.*/)) {
                const actualWeight1 = parcel.weight_value;
                const VolumeWeight1 = GetVolumeWeight(parcel);
                let chargeableWeight1 = 0;
                chargeableWeight1 = ChargeableWeight(VolumeWeight1, actualWeight1);
                chargeableWeight += chargeableWeight1
            }
        });
    }
    return chargeableWeight;
};
export default ConsolidationChargeableWeight;
