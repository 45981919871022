import React, { memo } from "react";
import { Line } from "react-chartjs-2";
import moment from "moment";

const LineChart = ({ type, labels, data, backgroundColor, label }) => {
    const options = {
        fill: false,
        interaction: {
            intersect: false,
        },
        radius: 0,
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                display:false,
                anchor: "end",
                color: "#676e78",
                align: "top",
                offset: 4,
                font: {
                    weight: "bold",
                },
                formatter: Math.round,
            },
        },
        bezierCurve: false,
        indexAxis: "x",
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: "#9CA3AF",
                    font: {
                        size: 12,
                        weight: "500",
                    },
                },
                beginAtZero: false,
            },
            y: {
                type: "linear",
                display: true,
                grid: {
                    display: false,
                },
                ticks: {
                    color: "#9CA3AF",
                    font: {
                        size: 12,
                        weight: "500",
                    },
                },
            },
        },
        maintainAspectRatio: false,
        type: "time",
        time: {
            unit: "month",
        },
    };
    const dataLine = {
        labels: data?.map((val) => {
            return moment(val.Date).format("DD-MMM-YY");
        }),
        datasets: [
            {
                label: "Received Parcels",
                fill: true,
                lineTension: 0.5,
                backgroundColor: "#F4AC99",
                borderColor: "#F4AC99",
                hoverBackgroundColor: "rgba(255,99,132,0.4)",
                hoverBorderColor: "rgba(255,99,132,1)",
                borderWidth: 2,
                data: data?.map((val) => {
                    return val.count;
                }),
            },
        ],
    };

      
    return (
        <React.Fragment>
            <Line options={options} data={dataLine} width={100} height={50} />
        </React.Fragment>
    );
};

export default memo(LineChart);
